import { h } from "preact";

interface Props {
    errorMessage?: string;
    redirectUrl?: string;
}

const BASE_PATH = process.env.APP_BASE_PATH || "https://masterchallenge.me";

const ErrorEmailTaken = ({ errorMessage = "", redirectUrl }: Props) => {
    const defaultMessage = `The user email has already been taken.`;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                padding: "13px",
                borderRadius: "8px",
                marginRight: "auto",
                backgroundColor: "rgba(243, 119, 119, 0.4)",
                color: "rgb(165 0 0)",
            }}
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style={{
                    marginRight: 10,
                    width: "1.3rem",
                }}
            >
                <path d="M34.5 420.4c-1.6 2.8-2.5 6-2.5 9.3 0 10.2 8.2 18.4 18.4 18.4h411.2c10.2 0 18.4-8.2 18.4-18.4 0-3.3-.9-6.4-2.5-9.3l-201-344.6C272.2 68.5 264.4 64 256 64s-16.2 4.5-20.5 11.8l-201 344.6zM6.9 404.2l201-344.6C217.9 42.5 236.2 32 256 32s38.1 10.5 48.1 27.6l201 344.6c4.5 7.7 6.9 16.5 6.9 25.4 0 27.8-22.6 50.4-50.4 50.4H50.4C22.6 480 0 457.4 0 429.6c0-8.9 2.4-17.7 6.9-25.4zM256 160c8.8 0 16 7.2 16 16v128c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16zm-24 224a24 24 0 1 1 48 0 24 24 0 1 1-48 0z" />
            </svg>
            <div>
                <span>{errorMessage || defaultMessage}</span>
                <p
                    style={{
                        fontSize: 13,
                        marginTop: 5,
                    }}
                >
                    Already have an account?{" "}
                    <a
                        href={redirectUrl || `${BASE_PATH}/login`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                            color: "purple",
                        }}
                    >
                        Login
                    </a>
                </p>
            </div>
        </div>
    );
};

export default ErrorEmailTaken;
