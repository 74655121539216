// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mc-sdk-ui----hooks-errorMessage{margin-top:0.25rem;color:#ef4444;font-size:0.75rem;line-height:1rem}\n", "",{"version":3,"sources":["webpack://./hooks/style.scss"],"names":[],"mappings":"AAAA,iCACI,kBAAmB,CACnB,aAAc,CACd,iBAAkB,CAClB,gBAAiB","sourcesContent":[".errorMessage {\n    margin-top: 0.25rem;\n    color: #ef4444;\n    font-size: 0.75rem;\n    line-height: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorMessage": "mc-sdk-ui----hooks-errorMessage"
};
export default ___CSS_LOADER_EXPORT___;
