import { h, FunctionalComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import { sortArray } from "../../services/helpers";
import McEmbedWidgetsSDKOptions from "../../models/McEmbedWidgetsSDKOptions";
import { getEducationTypes, getSubjects } from "../../services/api";

import styles from "./style.scss";
import ReactSelectElement from "../ReactSelectElement";

interface FilterParams {
    include: string;
    page: {
        offset: number;
        limit: number;
    };
    filter: {
        is_public: number;
        state: number;
        subject_id?: string;
        organisation_id?: string;
        education_type_id?: string;
    };
    sort: string;
}

const subjectParams = {
    page: {
        offset: 0,
        limit: 999,
    },
    sort: "title",
};

const educationTypeParams = {
    page: {
        offset: 0,
        limit: 999,
    },
    sort: "-created_at",
};

const CHALLENGE_SPACE_SORT_OPTIONS = [
    { value: "-created_at", label: "Newest" },
    { value: "challenge_start_date", label: "Start date" },
    {
        value: "challenge_submit_deadline_expired,challenge_submit_deadline",
        label: "Submit deadline",
    },
];

// const CustomControl = ({ children, ...props }) => {
//     const [item] = props.getValue();

//     const icon =
//         item.value && item.value.includes("-")
//             ? faSortAmountDownAlt
//             : faSortAmountUpAlt;

//     const updateValue = () => {
//         if (item.value && item.value.includes("-")) {
//             props.setValue({ ...item, value: item.value.substring(1) });
//         } else {
//             props.setValue({ ...item, value: "-" + item.value });
//         }
//     };

//     return (
//         <components.Control {...props}>
//             <button
//                 type="button"
//                 onMouseDown={(e) => {
//                     updateValue();
//                     e.preventDefault();
//                     e.stopPropagation();
//                 }}
//                 onTouchStart={() => {
//                     updateValue();
//                 }}
//                 onTouchEnd={(e) => {
//                     e.preventDefault();
//                     e.stopPropagation();
//                 }}
//                 className={styles.select}
//             >
//                 <FontAwesomeIcon icon={icon} />
//             </button>
//             {children}
//         </components.Control>
//     );
// };

interface ChallengeSpaceFilterProps extends McEmbedWidgetsSDKOptions {
    currentValues: FilterParams;
    onChange(params: any): void | Promise<any>;
}

const ChallengeSpaceFilters: FunctionalComponent<ChallengeSpaceFilterProps> = ({
    apiBasePath,
    apiKey,
    containerId,
    currentValues,
    onChange,
}: ChallengeSpaceFilterProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [filterParams, setFilterParams] = useState<FilterParams>({
        include: "subject",
        page: {
            offset: 0,
            limit: 18,
        },
        filter: {
            is_public: 1,
            state: 1,
        },
        sort: "challenge_submit_deadline_expired,challenge_submit_deadline",
    });
    const [subjects, setSubjects] = useState<any[]>([]);
    const [educationTypes, setEducationTypes] = useState<any>([]);

    useEffect(() => {
        setFilterParams(currentValues);
    }, [currentValues]);

    useEffect(() => {
        const fetchData = async () => {
            const {
                data: { data: subjectList },
            } = await getSubjects(apiBasePath, apiKey, subjectParams);
            const {
                data: { data: educationTypeList },
            } = await getEducationTypes(
                apiBasePath,
                apiKey,
                educationTypeParams
            );
            if (Object.keys(subjectList).length) {
                const mappedSubjects = Object.keys(subjectList).map(
                    (s: string) => subjectList[s]
                );
                const mappedSubjectsSorted = sortArray(
                    "attributes.title",
                    mappedSubjects
                );
                setSubjects(mappedSubjectsSorted);
            }

            if (Object.keys(educationTypeList).length) {
                const educationTypeListMapped = Object.keys(
                    educationTypeList
                ).map((s: string) => educationTypeList[s]);
                setEducationTypes(educationTypeListMapped);
            }
        };
        fetchData()
            .then(() => setLoading(false))
            .catch((e) => {
                console.error(e);
                setLoading(false);
                setError(true);
            });
    }, []);

    const onSort = (sort: any) => {
        // Toggle minus(-) sign for ASC/DESC
        const newParams = {
            ...filterParams,
            page: {
                ...filterParams.page,
                offset: 0,
            },
            sort: sort.value,
        };
        setFilterParams(newParams);
        onChange(newParams);
    };

    const selectSubject = (subject: any) => {
        const newParams = {
            ...filterParams,
            page: {
                ...filterParams.page,
                offset: 0,
            },
            filter: {
                ...filterParams.filter,
                subject_id: subject ? subject.id : null,
            },
        };
        if (!subject) {
            delete newParams.filter.subject_id;
        }
        setFilterParams(newParams);
        onChange(newParams);
    };

    const selectEducationType = (t: any) => {
        const newParams = {
            ...filterParams,
            page: {
                ...filterParams.page,
                offset: 0,
            },
            filter: {
                ...filterParams.filter,
                education_type_id: t ? t.id : null,
            },
        };
        if (!t) {
            delete newParams.filter.education_type_id;
        }
        setFilterParams(newParams);
        onChange(newParams);
    };

    return !loading && !error ? (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                {subjects.length > 0 && (
                    <div className={styles.filterColumn}>
                        <label className={styles.label}>Topic</label>
                        <ReactSelectElement
                            shadowDomContainerId={containerId}
                            name="subjects"
                            instanceId="subjectSelect"
                            getOptionLabel={(option) => option.attributes.title}
                            getOptionValue={(option) => option}
                            value={
                                filterParams.filter &&
                                filterParams.filter.subject_id
                                    ? subjects.filter((subject) => {
                                          return (
                                              subject.id ===
                                              filterParams.filter.subject_id
                                          );
                                      })
                                    : null
                            }
                            placeholder="All topics"
                            options={subjects}
                            onChange={selectSubject}
                            isClearable={true}
                            isSearchable={false}
                            className={`${styles.selectContainer} ${styles.firstSelect}`}
                            classNamePrefix="react-select"
                        />
                    </div>
                )}

                {educationTypes.length > 0 && (
                    <div className={styles.filterColumn}>
                        <label className={styles.label}>Level</label>
                        <ReactSelectElement
                            shadowDomContainerId={containerId}
                            name="educationType"
                            instanceId="educationTypeSelect"
                            inputId="educationTypeInput"
                            getOptionLabel={(option) => option.attributes.name}
                            getOptionValue={(option) => option}
                            value={
                                filterParams.filter &&
                                filterParams.filter.education_type_id
                                    ? educationTypes.filter((t) => {
                                          return (
                                              t.id ===
                                              filterParams.filter
                                                  .education_type_id
                                          );
                                      })
                                    : null
                            }
                            placeholder="All levels"
                            options={educationTypes}
                            onChange={selectEducationType}
                            isClearable={true}
                            isSearchable={false}
                            className={`${styles.selectContainer} ${styles.secondSelect}`}
                            classNamePrefix="react-select"
                        />
                    </div>
                )}
                <div className={styles.filterColumnLast}>
                    <label className={styles.label}>Sort by</label>
                    <ReactSelectElement
                        shadowDomContainerId={containerId}
                        name="sortBy"
                        instanceId="sortBy"
                        inputId="sortBySort"
                        defaultValue={CHALLENGE_SPACE_SORT_OPTIONS.find((a) =>
                            a.value.includes("challenge_submit_deadline")
                        )}
                        value={CHALLENGE_SPACE_SORT_OPTIONS.find((option) => {
                            return option.value === filterParams.sort;
                        })}
                        placeholder="Newest"
                        options={CHALLENGE_SPACE_SORT_OPTIONS}
                        onChange={onSort}
                        // components={{ Control: CustomControl }}
                        isClearable={false}
                        isSearchable={false}
                        className={`${styles.selectContainer} ${styles.lastSelect}`}
                        classNamePrefix="react-select"
                    />
                </div>
            </div>
        </div>
    ) : null;
};

export default ChallengeSpaceFilters;
