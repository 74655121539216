// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mc-sdk-ui-CompanyName-name{padding:0.4rem 0}.mc-sdk-ui-CompanyName-line{color:#3d3d3d;font-size:0.9rem;padding:0.2rem 0;display:flex;align-items:center}.mc-sdk-ui-CompanyName-line .mc-sdk-ui-CompanyName-icon{margin-right:0.4rem;display:inline-block;text-align:center}\n", "",{"version":3,"sources":["webpack://./components/CompanyName/style.scss"],"names":[],"mappings":"AAAA,4BACI,gBAAiB,CACpB,4BAGG,aAAc,CACd,gBAAiB,CACjB,gBAAiB,CACjB,YAAa,CACb,kBAAmB,CALvB,wDAQQ,mBAAoB,CACpB,oBAAqB,CACrB,iBAAkB","sourcesContent":[".name {\n    padding: 0.4rem 0;\n}\n\n.line {\n    color: #3d3d3d;\n    font-size: 0.9rem;\n    padding: 0.2rem 0;\n    display: flex;\n    align-items: center;\n\n    .icon {\n        margin-right: 0.4rem;\n        display: inline-block;\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": "mc-sdk-ui-CompanyName-name",
	"line": "mc-sdk-ui-CompanyName-line",
	"icon": "mc-sdk-ui-CompanyName-icon"
};
export default ___CSS_LOADER_EXPORT___;
