import { h, FunctionalComponent } from "preact";
import SimpleReactValidator from "simple-react-validator";
import { useReducer, useRef, useState } from "preact/hooks";
import McTeamFormationSignupData from "../../models/McTeamFormationSignupData";

import styles from "./style.scss";
import McEmbedWidgetsSDKOptions from "../../models/McEmbedWidgetsSDKOptions";
import { registerTeamFormationUser } from "../../services/api";
import Button from "../Button";

const BASE_PATH = process.env.APP_BASE_PATH || "https://masterchallenge.me";

const TeamFormationSignupForm: FunctionalComponent<McEmbedWidgetsSDKOptions> = (
    props: McEmbedWidgetsSDKOptions
) => {
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const [loading, setLoading] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [formState, setFormState] = useState<McTeamFormationSignupData>({
        firstname: "",
        lastname: "",
        email: "",
        consent: "",
    });
    const {
        apiBasePath,
        apiKey,
        organisationId,
        widgetOptions: {
            teamFormationId,
            teamFormationSignupCode,
            challengeSpaceId,
        },
    } = props;

    const validatorConfig: any = {
        autoForceUpdate: { forceUpdate: forceUpdate },
        locale: "en",
        element: (message: string) => (
            <p className={styles.errorMessage}>{message}</p>
        ),
    };
    const validator = useRef(new SimpleReactValidator(validatorConfig));

    const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const name = target.name;

        const newState = {
            ...formState,
            [name]: value,
        };
        setFormState(newState);
    };

    const handleCheckboxChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const value = target.checked;
        const name = target.name;

        const newState = {
            ...formState,
            [name]: value,
        };

        setFormState(newState);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        // submit action here with preset org id
        const payload = { ...formState, organisation_id: organisationId };

        // Remove consent flag (not saved on BE)
        delete payload.consent;

        if (!teamFormationId) {
            return setError("teamFormationId is missing");
        }

        if (!teamFormationSignupCode) {
            return setError("teamFormationSignupCode is missing");
        }

        registerTeamFormationUser(
            apiBasePath,
            apiKey,
            teamFormationId,
            teamFormationSignupCode,
            payload
        )
            .then(() => {
                setSubmitted(true);
                setLoading(false);
            })
            .catch((err: any) => {
                setLoading(false);
                setError(err);
            });
    };

    return submitted && !loading ? (
        <div className={styles.submittedContainer}>
            <h2 className={styles.submittedTitle}>Check your mail!</h2>
            <p className={styles.submittedText}>
                Thanks for registering! You will receive an email with more
                instructions from the Master Challenge platform (which is the
                software we use).
            </p>
        </div>
    ) : loading ? (
        <p>Processing..</p>
    ) : (
        <form className={styles.form} onSubmit={handleSubmit}>
            <div style={{ marginBottom: "1rem" }}>
                <label className={styles.label} htmlFor="firstname">
                    First name
                </label>
                <input
                    className={styles.input}
                    name="firstname"
                    value={formState.firstname}
                    onChange={handleInputChange}
                    onBlur={() => validator.current.showMessageFor("firstname")}
                    id="firstname"
                    type="text"
                    placeholder="First name"
                    required
                />
                {validator.current.message(
                    "firstname",
                    formState.firstname,
                    "required|min:2"
                )}
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <label className={styles.label} htmlFor="lastname">
                    Last name
                </label>
                <input
                    className={styles.input}
                    name="lastname"
                    value={formState.lastname}
                    onChange={handleInputChange}
                    onBlur={() => validator.current.showMessageFor("lastname")}
                    id="lastname"
                    type="text"
                    placeholder="Last name"
                    required
                />
                {validator.current.message(
                    "lastname",
                    formState.lastname,
                    "required|min:2"
                )}
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <label className={styles.label} htmlFor="email">
                    Email
                </label>
                <input
                    className={styles.input}
                    name="email"
                    value={formState.email}
                    onChange={handleInputChange}
                    onBlur={() => validator.current.showMessageFor("email")}
                    id="email"
                    type="email"
                    placeholder="Email"
                    required
                />
                {validator.current.message(
                    "email",
                    formState.email,
                    "required|email"
                )}
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <label
                    className={styles.label}
                    style={{ width: "66.666667%" }}
                    htmlFor="consent"
                >
                    <input
                        style={{ marginRight: "0.5rem", lineHeight: 1.25 }}
                        type="checkbox"
                        value={formState.consent}
                        onBlur={() =>
                            validator.current.showMessageFor(
                                "privacy policy and terms"
                            )
                        }
                        onChange={handleCheckboxChange}
                        name="consent"
                        id="consent"
                    />
                    <span
                        style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
                    >
                        <span style={{ fontWeight: "normal" }}>
                            I agree with the
                        </span>{" "}
                        <a
                            className={styles.link}
                            rel="noreferrer"
                            href={`${BASE_PATH}/static/files/privacy_policy.pdf`}
                            target="_blank"
                        >
                            privacy policy
                        </a>{" "}
                        and the{" "}
                        <a
                            className={styles.link}
                            rel="noreferrer"
                            href={`${BASE_PATH}/static/files/terms_and_conditions.pdf`}
                            target="_blank"
                        >
                            terms &amp; conditions
                        </a>
                    </span>
                </label>
                {validator.current.message(
                    "privacy policy and terms",
                    formState.consent,
                    "accepted|required"
                )}
                <div className={styles.buttonContainer}>
                    <Button
                        variant="primary"
                        wrapper="button"
                        disabled={!validator.current.allValid()}
                        type="submit"
                    >
                        Register
                    </Button>
                    <a
                        class={styles.link}
                        href={`${BASE_PATH}/challenge-spaces/${challengeSpaceId}/user-registration?code=${teamFormationSignupCode}&formationId=${teamFormationId}`}
                        rel="noreferrer"
                        target="_blank"
                    >
                        Already have an account on MasterChallenge?
                    </a>
                </div>

                {error && (
                    <p className={styles.errorMessage}>
                        Something went wrong. All details filled?
                    </p>
                )}
            </div>
        </form>
    );
};

export default TeamFormationSignupForm;
