/**
 * Redux-like style of actions
 * @param  {Object} store piece of state
 * @return {Object}       state
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Actions = (store: any) => ({
    setApiBasePath(state: any, basePath: string) {
        return { apiBasePath: basePath };
    },

    startLoading() {
        return { isLoading: true, error: "" };
    },

    stopLoading() {
        return { isLoading: false };
    },

    setError(state: any, newError: {}) {
        return { error: newError, isLoading: false };
    },
});

export default Actions;
