import { h, FunctionalComponent } from "preact";

import styles from "./styles.scss";
const Loader: FunctionalComponent = () => {
    return (
        <div className={styles.loader}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M326.3 9.8C304 3.4 280.4 0 256 0 114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256c0-24.4-3.4-48-9.8-70.3l-22.8 25.4c-1.1 1.2-2.1 2.3-3.3 3.4 2.5 13.5 3.8 27.3 3.8 41.5 0 123.7-100.3 224-224 224S32 379.7 32 256 132.3 32 256 32c14.2 0 28.1 1.3 41.5 3.8 1.1-1.1 2.2-2.2 3.4-3.3l25.4-22.7zm-51.7 87.3c-6.1-.7-12.3-1.1-18.6-1.1-88.4 0-160 71.6-160 160s71.6 160 160 160 160-71.6 160-160c0-6.3-.4-12.5-1.1-18.6-2.7-.2-5.4-.5-8.1-.9l-25-4.2c1.4 7.7 2.2 15.6 2.2 23.7 0 70.7-57.3 128-128 128s-128-57.3-128-128 57.3-128 128-128c8.1 0 16 .8 23.7 2.2l-4.2-25c-.5-2.7-.8-5.4-.9-8.1zm66.9 96.1 70.4 11.7c16.3 2.7 32.8-3.1 43.8-15.5l43.9-49.4c11.7-13.1 5.8-33.9-11-38.9l-59.7-17.9-18-59.8c-5-16.8-25.8-22.7-38.9-11l-49.4 43.9c-12.3 11-18.2 27.5-15.5 43.8l11.7 70.4-74.2 74.2c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l74.2-74.2zm27.8-27.8 51.3-51.3 46.8 14-35.6 40c-3.7 4.1-9.2 6.1-14.6 5.2l-47.9-8zm28.6-73.9-51.3 51.3-8-47.9c-.9-5.4 1-10.9 5.2-14.6l40-35.6 14 46.8z" />
            </svg>
        </div>
    );
};

export default Loader;
