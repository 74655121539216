import { h } from "preact";
import { useRef, useReducer } from "preact/hooks";
import SimpleReactValidator from "simple-react-validator";

import styles from "./style.scss";
import { countWords } from "../services/helpers";

const useValidator = () => {
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const validator = useRef<SimpleReactValidator>(
        new SimpleReactValidator({
            autoForceUpdate: { forceUpdate: forceUpdate },
            locale: "en",
            validators: {
                prefixedUrl: {
                    message:
                        "Link must start with https:// e.g. https://google.com",
                    rule: (val: string) => val.startsWith("https://"),
                },
                maxwords: {
                    // name the rule
                    message: "A maximum of :values words is allowed",
                    rule: (val, params) => {
                        return countWords(val) <= params;
                    },
                    messageReplace: (message, params) =>
                        message.replace(":values", params), // optional
                },
            },
            element: (message: any) => (
                <p className={styles.errorMessage}>{message}</p>
            ),
        })
    ).current;
    return validator;
};

export default useValidator;
