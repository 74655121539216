import { h, FunctionalComponent } from "preact";
import { useRef, useState } from "preact/hooks";
import McStakeholderSignupData from "../../models/McStakeholderSignupData";

import styles from "./style.scss";
import McEmbedWidgetsSDKOptions from "../../models/McEmbedWidgetsSDKOptions";
import {
    registerCompanyOwner,
    registerCompanyUserCompany,
} from "../../services/api";
import CompanySubmitForm from "../CompanySubmitForm";
import McCompanyFormData from "../../models/McCompanyFormData";
import useValidator from "../../hooks/useValidator";
import ErrorEmailTaken from "../OpenChallenge/ErrorEmailTaken";
import { mysqlDateOrEmpty, prependObjectKeys } from "../../services/helpers";
import Button from "../Button";

const BASE_PATH = process.env.APP_BASE_PATH || "https://masterchallenge.me";

const StakeholderSignupForm: FunctionalComponent<McEmbedWidgetsSDKOptions> = (
    props: McEmbedWidgetsSDKOptions
) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [error, setError] = useState<any>("");
    const [hasCompany, setHasCompany] = useState<boolean | string>("");
    const [companyData, setCompanyData] = useState<McCompanyFormData>({
        city_name: "",
        description: "",
        employee_count: "",
        founded_at: "",
        house_number: "",
        house_number_extension: "",
        iban: "",
        logo_url: "",
        name: "",
        postal_code: "",
        street_name: "",
        website_url: "",
        organisation_id: props.organisationId,
    });
    const [formState, setFormState] = useState<McStakeholderSignupData>({
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        linkedin_url: "",
        phone_number: "",
        consent: "",
    });
    const { apiBasePath, apiKey, organisationId } = props;
    const widgetObj = {
        apiBasePath: apiBasePath,
        apiKey: apiKey,
        organisationId: organisationId,
    };

    const validator = useValidator();
    const companyFormValidator = useRef<any>();

    const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const name = target.name;

        const newState = {
            ...formState,
            [name]: value,
        };
        setFormState(newState);
    };

    const handleCheckboxChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const value = target.checked;
        const name = target.name;

        const newState = {
            ...formState,
            [name]: value,
        };

        setFormState(newState);
    };

    const handleHasCompanyChange = (
        event: React.FormEvent<HTMLInputElement>
    ) => {
        const target = event.target as HTMLInputElement;
        const value = target.checked;

        setHasCompany(value);
    };

    const handleCompanyDataChange = (
        _type: any,
        formData: any,
        _valid: boolean
    ) => {
        setCompanyData(formData);
    };

    const handleSubmit = (e: SubmitEvent) => {
        e.preventDefault();

        const allValid = validator.allValid();
        let companyValid: boolean = true;
        if (!allValid) {
            validator.showMessages();
        }

        if (hasCompany) {
            companyValid = companyFormValidator?.current?.validator?.allValid();
            companyFormValidator?.current?.validator?.showMessages();
        }

        if (!allValid || !companyValid) {
            return;
        }

        let promise;
        let payload;
        if (hasCompany) {
            let userData = { ...formState, organisation_id: organisationId };
            payload = {
                ...prependObjectKeys("user_", userData),
                ...prependObjectKeys("company_", companyData),
            };
            payload.company_founded_at = mysqlDateOrEmpty(
                companyData.founded_at
            );
            promise = registerCompanyUserCompany(apiBasePath, apiKey, payload);
        } else {
            payload = { ...formState, organisation_id: organisationId };
            delete payload.consent;
            promise = registerCompanyOwner(apiBasePath, apiKey, payload);
        }

        promise
            .then((res: any) => {
                return res;
            })
            .then(() => {
                setSubmitted(true);
                setLoading(false);
            })
            .catch((err: any) => {
                console.log(err);
                setLoading(false);
                var errorMessage =
                    err?.response?.data?.message ??
                    "Something went wrong. All details filled?";
                setError(errorMessage);
            });
    };

    return submitted && !loading ? (
        <div className={styles.submittedContainer}>
            <h2 className={styles.submittedTitle}>Check your mail!</h2>
            <p className={styles.submittedText}>
                Thanks for registering! You will receive a verification email
                from the Master Challenge platform (which is the software we
                use). Please check your email (also your spam folder) with a
                link to confirm your account and complete your profile.
            </p>
        </div>
    ) : loading ? (
        <p>Processing..</p>
    ) : (
        <form className={styles.form} noValidate onSubmit={handleSubmit}>
            <div style={{ marginBottom: "1rem" }}>
                <label className={styles.label} htmlFor="firstname">
                    First name
                </label>
                <input
                    className={styles.input}
                    name="firstname"
                    value={formState.firstname}
                    onChange={handleInputChange}
                    onBlur={() => validator.showMessageFor("firstname")}
                    id="firstname"
                    type="text"
                    placeholder="First name"
                    required
                />
                {validator.message(
                    "firstname",
                    formState.firstname,
                    "required|min:2"
                )}
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <label className={styles.label} htmlFor="lastname">
                    Last name
                </label>
                <input
                    className={styles.input}
                    name="lastname"
                    value={formState.lastname}
                    onChange={handleInputChange}
                    onBlur={() => validator.showMessageFor("lastname")}
                    id="lastname"
                    type="text"
                    placeholder="Last name"
                    required
                />
                {validator.message(
                    "lastname",
                    formState.lastname,
                    "required|min:2"
                )}
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <label className={styles.label} htmlFor="email">
                    Email
                </label>
                <input
                    className={styles.input}
                    name="email"
                    value={formState.email}
                    onChange={handleInputChange}
                    onBlur={() => validator.showMessageFor("email")}
                    id="email"
                    type="email"
                    placeholder="Email"
                    required
                />
                {validator.message("email", formState.email, "required|email")}
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <label className={styles.label} htmlFor="password">
                    Password
                </label>
                <input
                    className={styles.input}
                    name="password"
                    value={formState.password}
                    onChange={handleInputChange}
                    onBlur={() => validator.showMessageFor("password")}
                    id="password"
                    type="password"
                    placeholder="Password"
                    required
                />
                {validator.message(
                    "password",
                    formState.password,
                    "required|min:6"
                )}
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <label className={styles.label} htmlFor="phone_number">
                    Phone number
                </label>
                <input
                    className={styles.input}
                    name="phone_number"
                    value={formState.phone_number}
                    onChange={handleInputChange}
                    id="phone_number"
                    type="text"
                    placeholder="Phone number"
                />
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <label className={styles.label} htmlFor="linkedin_url">
                    Linkedin url
                </label>
                <input
                    className={styles.input}
                    name="linkedin_url"
                    value={formState.linkedin_url}
                    onChange={handleInputChange}
                    onBlur={() => validator.showMessageFor("linkedin")}
                    id="linkedin_url"
                    placeholder="https://linkedin.com/johndoe"
                    type="text"
                />
                {validator.message(
                    "linkedin url",
                    formState.linkedin_url,
                    "prefixedUrl|url"
                )}
            </div>

            <div style={{ marginBottom: "1rem" }}>
                <label
                    className={styles.label}
                    style={{ width: "66.666667%" }}
                    htmlFor="hasCompany"
                >
                    <input
                        style={{ marginRight: "0.5rem", lineHeight: 1.25 }}
                        type="checkbox"
                        value={hasCompany}
                        onChange={handleHasCompanyChange}
                        name="hasCompany"
                        id="hasCompany"
                    />
                    <span
                        style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
                    >
                        <span style={{ fontWeight: "normal" }}>
                            {`I'm part of a company/organisation`}
                        </span>{" "}
                    </span>
                </label>
            </div>

            {/* Company details are optional */}
            {hasCompany && (
                <div className={styles.companyDetailsWrapper}>
                    <h3 className={styles.companyDetailsHeader}>
                        Company details
                    </h3>
                    <CompanySubmitForm
                        widgetObj={widgetObj}
                        onDataChange={handleCompanyDataChange}
                        data={companyData}
                        ref={companyFormValidator}
                    />
                </div>
            )}

            <div style={{ marginBottom: "1rem" }}>
                <label
                    className={styles.label}
                    style={{ width: "66.666667%" }}
                    htmlFor="consent"
                >
                    <input
                        style={{ marginRight: "0.5rem", lineHeight: 1.25 }}
                        type="checkbox"
                        value={formState.consent}
                        onBlur={() =>
                            validator.showMessageFor("privacy policy and terms")
                        }
                        onChange={handleCheckboxChange}
                        name="consent"
                        id="consent"
                    />
                    <span
                        style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
                    >
                        <span style={{ fontWeight: "normal" }}>
                            I agree with the
                        </span>{" "}
                        <a
                            className={styles.link}
                            rel="noreferrer"
                            href={`${BASE_PATH}/static/files/privacy_policy.pdf`}
                            target="_blank"
                        >
                            privacy policy
                        </a>{" "}
                        and the{" "}
                        <a
                            className={styles.link}
                            rel="noreferrer"
                            href={`${BASE_PATH}/static/files/terms_and_conditions.pdf`}
                            target="_blank"
                        >
                            terms &amp; conditions
                        </a>
                    </span>
                </label>
                {validator.message(
                    "privacy policy and terms",
                    formState.consent,
                    "accepted|required"
                )}

                {error && error?.errors?.email && (
                    <div
                        style={{
                            marginBottom: "1rem",
                        }}
                    >
                        <ErrorEmailTaken
                            redirectUrl={`${BASE_PATH}/account/profile?tab=my_profile`}
                        />
                    </div>
                )}
                <div className={styles.buttonContainer}>
                    <Button variant="primary" wrapper="button" type="submit">
                        Register
                    </Button>
                    <a
                        class={styles.link}
                        href={`${BASE_PATH}/account/profile?tab=my_profile`}
                        rel="noreferrer"
                        target="_blank"
                    >
                        Already have an account on MasterChallenge?
                    </a>
                </div>

                {error && <p className={styles.errorMessage}>{error}</p>}
            </div>
        </form>
    );
};

export default StakeholderSignupForm;
