import { h, FunctionalComponent } from "preact";

import styles from "./styles.scss";

interface ButtonProps {
    variant: "primary" | "secondary";
    wrapper: "a" | "button";
    children: any;
    className?: string;
    [key: string]: any;
}

const Button: FunctionalComponent<ButtonProps> = ({
    variant,
    wrapper,
    children,
    className = "",
    ...props
}: ButtonProps) => {
    return wrapper === "a" ? (
        <a
            className={`${styles.button as string} ${
                variant === "primary"
                    ? (styles.buttonPrimary as string)
                    : (styles.buttonSecondary as string)
            } ${className}`}
            {...props}
        >
            {children}
        </a>
    ) : (
        <button
            type="button"
            className={`${styles.button as string} ${
                variant === "primary"
                    ? (styles.buttonPrimary as string)
                    : (styles.buttonSecondary as string)
            } ${className}`}
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;
