import axios, { AxiosRequestConfig, Method } from "axios";
import { serialize } from "./helpers";

export const makeAxiosRequestConfig = (
    method: Method,
    path: string,
    data?: any,
    params?: any
): AxiosRequestConfig => {
    const headers: AxiosRequestConfig["headers"] = {
        "Content-Type": "application/vnd.api+json",
        Accept: "application/vnd.api+json",
    };

    return {
        method,
        url: `${path}`,
        data,
        params,
        headers,
    };
};

export interface CollectionApiResponse {
    data: any[];
    included?: any[];
    links?: {
        first?: string;
        last?: string;
    };
    meta: {
        totalItems: number;
        currentPage: number;
        itemsPerPage: number;
    };
}

export const getSubjects = (
    apiBasePath: string,
    apiKey: string,
    params: any
) => {
    const cfg = makeAxiosRequestConfig(
        "get",
        `${apiBasePath}/subjects?apiKey=${apiKey}`,
        {},
        params
    );
    return axios<CollectionApiResponse>(cfg);
};

export const getEducationTypes = (
    apiBasePath: string,
    apiKey: string,
    params: any
) => {
    const cfg = makeAxiosRequestConfig(
        "GET",
        `${apiBasePath}/education-types?apiKey=${apiKey}`,
        {},
        params
    );
    return axios<CollectionApiResponse>(cfg);
};

export const getOrganisation = (
    apiBasePath: string,
    apiKey: string,
    OrganisationId: string,
    params?: any
) => {
    const cfg = makeAxiosRequestConfig(
        "GET",
        `${apiBasePath}/organisations/${OrganisationId}?apiKey=${apiKey}`,
        {},
        params
    );
    return axios<CollectionApiResponse>(cfg);
};

export const getChallengeSpaces = (
    apiBasePath: string,
    apiKey: string,
    params: any
) => {
    const cfg = makeAxiosRequestConfig(
        "GET",
        `${apiBasePath}/challenge-spaces?apiKey=${apiKey}`,
        {},
        params
    );
    return axios<CollectionApiResponse>(cfg);
};

export const getChallengeSpace = (
    apiBasePath: string,
    apiKey: string,
    challengeSpaceId: string,
    params?: any
) => {
    const cfg = makeAxiosRequestConfig(
        "GET",
        `${apiBasePath}/challenge-spaces/${challengeSpaceId}?apiKey=${apiKey}`,
        {},
        params
    );
    return axios<CollectionApiResponse>(cfg);
};

export const createCompanyChallenge = (
    apiBasePath: string,
    apiKey: string,
    params?: any
) => {
    // Initially leaving answers empty for the back-end
    const finalParams = { answers: [], ...params };
    const cfg = makeAxiosRequestConfig(
        "POST",
        `${apiBasePath}/company-challenges?apiKey=${apiKey}`,
        finalParams
    );
    return axios<CollectionApiResponse>(cfg);
};

export const createCompany = (
    apiBasePath: string,
    apiKey: string,
    params?: any
) => {
    const cfg = makeAxiosRequestConfig(
        "POST",
        `${apiBasePath}/companies?apiKey=${apiKey}`,
        params
    );
    return axios<CollectionApiResponse>(cfg);
};

export const registerSpaceAdmin = (
    apiBasePath: string,
    apiKey: string,
    params?: any
) => {
    const cfg = makeAxiosRequestConfig(
        "POST",
        `${apiBasePath}/users/space-admin?apiKey=${apiKey}`,
        params
    );
    return axios<CollectionApiResponse>(cfg);
};

export const registerCompanyOwner = (
    apiBasePath: string,
    apiKey: string,
    params?: any
) => {
    const cfg = makeAxiosRequestConfig(
        "POST",
        `${apiBasePath}/users/company-owner?apiKey=${apiKey}`,
        params
    );
    return axios<CollectionApiResponse>(cfg);
};

export const registerCompanyUserCompany = (
    apiBasePath: string,
    apiKey: string,
    params?: any
) => {
    const cfg = makeAxiosRequestConfig(
        "POST",
        `${apiBasePath}/companies/company-user-company?apiKey=${apiKey}`,
        params
    );
    return axios<CollectionApiResponse>(cfg);
};


export const registerTeamFormationUser = (
    apiBasePath: string,
    apiKey: string,
    teamFormationId: string,
    teamFormationSignupCode: string,
    params?: any
) => {
    const cfg = makeAxiosRequestConfig(
        "POST",
        `${apiBasePath}/team-formations/${teamFormationId}/team-formation-user?apiKey=${apiKey}&code=${teamFormationSignupCode}`,
        params
    );
    return axios<CollectionApiResponse>(cfg);
};

// getQuestions(params: any, options?: any) {
//     return get('/questions', params, options)
// },

export async function getQuestions(
    apiBasePath: string,
    apiKey: string,
    params: any
) {
    const cfg = makeAxiosRequestConfig(
        "GET",
        `${apiBasePath}/questions?apiKey=${apiKey}`,
        {},
        params
    );
    return axios<CollectionApiResponse>(cfg);
}

export async function getQuestionAnswers(
    apiBasePath: string,
    apiKey: string,
    params: any,
    options?: any
) {
    // return get('/questions/answers', params, options)
    const cfg = makeAxiosRequestConfig(
        "GET",
        `${apiBasePath}/questions/answers?apiKey=${apiKey}`,
        {},
        params
    );
    return axios<CollectionApiResponse>(cfg);
}

export async function createQuestionAnswers(apiBasePath, apiKey, params: any) {
    // return post('/questions/answers', params)
    const cfg = makeAxiosRequestConfig(
        "GET",
        `${apiBasePath}/questions/answers?apiKey=${apiKey}`,
        {},
        params
    );
    return axios<CollectionApiResponse>(cfg);
}

export async function updateQuestionAnswers(apiBasePath, apiKey, params: any) {
    // return put('/questions/answers', params)
    const cfg = makeAxiosRequestConfig(
        "GET",
        `${apiBasePath}/questions/answers?apiKey=${apiKey}`,
        {},
        params
    );
    return axios<CollectionApiResponse>(cfg);
}

export const kvkSearch = (
    apiBasePath: string,
    apiKey: string,
    params?: any
) => {
    params.apiKey = apiKey;
    const cfg = makeAxiosRequestConfig(
        "GET",
        `${apiBasePath}/kvk/search?${serialize(params)}`,
        {},
        params
    );
    return axios<CollectionApiResponse>(cfg);
};

export const kvkDetail = (apiBasePath: string, apiKey: string, kvknr: any) => {
    let params = { apiKey: apiKey };
    const cfg = makeAxiosRequestConfig(
        "GET",
        `${apiBasePath}/kvk/detail/${kvknr}?${serialize(params)}`,
        {},
        params
    );
    return axios<CollectionApiResponse>(cfg);
};
