import { h, FunctionalComponent } from "preact";
import createStore from "unistore";
import { Provider } from "unistore/preact";
import devtools from "unistore/devtools";
import Actions from "./actions";

export interface StoreContextWrapperProps {
    children?: any;
}

export interface StoreContextState {
    apiBasePath?: string;
    token: string;
    isLoading: boolean;
    initialized: boolean;
    error: any;
}

/**
 * Set initial state
 * @type {Object}
 */
const initialState: StoreContextState = {
    initialized: false,
    apiBasePath: "",
    token: "",
    isLoading: false,
    error: ""
};

/**
 * Create store object
 * @type {Store}
 */
const store =
    process.env.NODE_ENV === "production"
        ? createStore(initialState)
        : devtools(createStore(initialState));

const StoreContextWrapper: FunctionalComponent<StoreContextWrapperProps> = ({
    children
}: StoreContextWrapperProps) => {
    return <Provider store={store}>{children}</Provider>;
};

export { StoreContextWrapper, Actions };
