import { h, FunctionalComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import McEmbedWidgetsSDKOptions from "../../models/McEmbedWidgetsSDKOptions";
import { getChallengeSpaces, getSubjects } from "../../services/api";
import ChallengeSpaceCard from "../ChallengeSpaceCard";
import ChallengeSpaceFilters from "../ChallengeSpaceFilters";

import styles from "./style.scss";
import Button from "../Button";

interface SpacesOverViewParams {
    include: string;
    page: { offset: number; limit: number };
    filter: {
        subject_id?: string;
        is_public: number;
        state: number;
        use_challenges?: number;
        organisation_id: string;
    };
    sort: string;
}

const SpacesOverview: FunctionalComponent<McEmbedWidgetsSDKOptions> = (
    props: McEmbedWidgetsSDKOptions
) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [spaceParams, setSpaceParams] = useState<SpacesOverViewParams>({
        include: "subject",
        page: {
            offset: 0,
            limit: 18,
        },
        filter: {
            is_public: 1,
            use_challenges: 1,
            state: 1,
            organisation_id: props.organisationId,
        },
        sort: "challenge_submit_deadline_expired,challenge_submit_deadline",
    });
    const [spaces, setSpaces] = useState<any[]>([]);
    const [subjects, setSubjects] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const { widgetOptions } = props;

    const subjectParams = {
        page: {
            offset: 0,
            limit: 999,
        },
        sort: "title",
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            const { apiBasePath, apiKey } = props;
            const {
                data: { data: spaceRes, meta },
            } = await getChallengeSpaces(apiBasePath, apiKey, spaceParams);
            setSpaces(spaceRes);
            setTotalItems(meta.totalItems);

            const {
                data: { data: subjectList },
            } = await getSubjects(apiBasePath, apiKey, subjectParams);
            const mappedSubjects = Object.keys(subjectList).map((key) => {
                return subjectList[key];
            });

            setSubjects(mappedSubjects);
        };
        fetchData()
            .then(() => setLoading(false))
            .catch((e) => {
                console.error(e);
                setLoading(false);
                setError(true);
            });
    }, []);

    const loadMore = async () => {
        const { apiBasePath, apiKey } = props;
        const newParams = {
            ...spaceParams,
            page: {
                ...spaceParams.page,
                offset: spaceParams.page.offset + 18,
            },
        };
        setSpaceParams(newParams);
        const {
            data: { data: spaceRes, meta },
        } = await getChallengeSpaces(apiBasePath, apiKey, newParams);
        setTotalItems(meta.totalItems);
        setSpaces([...spaces, ...spaceRes]);
        // api call
    };

    const handleChange = async (filters: SpacesOverViewParams) => {
        const { apiBasePath, apiKey } = props;
        setSpaceParams({ ...filters });
        const {
            data: { data: spaceRes, meta },
        } = await getChallengeSpaces(apiBasePath, apiKey, filters);
        setTotalItems(meta.totalItems);
        setSpaces(spaceRes);
        // api call
    };

    return loading ? (
        <p>Loading...</p>
    ) : error ? (
        <p>Something went wrong, please contact MasterChallenge support</p>
    ) : (
        <div>
            {widgetOptions.hideChallengeSpaceOverviewFilters ? null : (
                <ChallengeSpaceFilters
                    {...props}
                    currentValues={spaceParams}
                    onChange={handleChange}
                />
            )}
            <div className={styles.outerWrapper}>
                <div className={styles.containerWrapper}>
                    <div className={styles.container}>
                        {spaces &&
                            Object.keys(spaces).map((key, index) => {
                                const space = spaces[key];
                                const subject = subjects.find(
                                    (s) =>
                                        s.id ===
                                        space.relationships?.subject?.data?.id
                                );

                                return (
                                    <div
                                        className={`${styles.cardWrapper} ${
                                            widgetOptions.columnCount == 2
                                                ? `${styles.cardColumn2}`
                                                : ""
                                        }`}
                                        key={index}
                                    >
                                        <ChallengeSpaceCard
                                            space={space}
                                            detailPage={
                                                widgetOptions.challengeSpaceDetailPage
                                            }
                                            subject={subject}
                                            key={index}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
            <div
                className={styles.loadMore}
                style={{ minHeight: !spaces ? "600px" : "auto" }}
            >
                {Object.keys(spaces).length > 0 &&
                    totalItems > Object.keys(spaces).length && (
                        <Button
                            variant="primary"
                            onClick={loadMore}
                            wrapper="button"
                        >
                            Load more
                        </Button>
                    )}
                {!Object.keys(spaces).length && (
                    <p className={styles.noResults}>No results found</p>
                )}
            </div>
        </div>
    );
};

export default SpacesOverview;
