import { h, FunctionalComponent } from "preact";
import { useEffect, useRef, useState } from "preact/hooks";

import ChallengeSubmitForm from "../ChallengeSubmitForm";
import CompanySubmitForm from "../CompanySubmitForm";
import UserSubmitForm from "../UserSubmitForm";
import { createCompanyChallenge, getOrganisation } from "../../services/api";

import McEmbedWidgetsSDKOptions from "../../models/McEmbedWidgetsSDKOptions";
import McChallengeFormData from "../../models/McChallengeFormData";
import McCompanyFormData from "../../models/McCompanyFormData";
import McUserFormData from "../../models/McUserFormData";
import McChallengeFormStep from "../../models/McChallengeFormStep";
import { isEmpty, mysqlDateOrEmpty, scrollToId } from "../../services/helpers";
import ErrorEmailTaken from "./ErrorEmailTaken";

import styles from "./style.scss";
import Button from "../Button";
import Loader from "../Loader";

interface SubmitChallengeState {
    steps: McChallengeFormStep[];
    userFormData: McUserFormData;
    challengeFormData: McChallengeFormData;
    companyFormData: McCompanyFormData;
    errors: Record<string, never>;
    error: Record<string, never> | string;
}

const BASE_PATH = process.env.APP_BASE_PATH || "https://masterchallenge.me";

const OpenChallenge: FunctionalComponent<McEmbedWidgetsSDKOptions> = (
    props: McEmbedWidgetsSDKOptions
) => {
    const { organisationId, apiBasePath, apiKey, containerId } = props;
    const widgetObj = {
        apiBasePath: apiBasePath,
        apiKey: apiKey,
        organisationId: organisationId,
        containerId,
    };

    const submitSectionRef = useRef(null);
    let stepForm;
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [organisation, setOrganisation] = useState<any>({});
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [challengeState, setChallengeState] = useState<SubmitChallengeState>({
        steps: [
            {
                key: "challenge",
                title: "Your challenge",
                valid: false,
            },
            {
                key: "company",
                title: "Your company",
                valid: false,
            },
            {
                key: "user",
                title: "Register account",
                valid: false,
            },
        ],
        challengeFormData: {
            title: "",
            summary: "",
            team_count_preference: 1,
            description: "",
            open_challenge_type: "organisation",
        },
        companyFormData: {
            city_name: "",
            description: "",
            employee_count: "",
            founded_at: "",
            house_number: "",
            house_number_extension: "",
            iban: "",
            logo_url: "",
            name: "",
            postal_code: "",
            street_name: "",
            website_url: "",
        },
        userFormData: {
            email: "",
            linkedin_url: "",
            phone_number: "",
            firstname: "",
            lastname: "",
            password: "",
        },
        errors: {},
        error: "",
    });

    const loadOrganisation = () => {
        const fetchData = async () => {
            setLoading(true);
            const { apiBasePath, apiKey } = props;
            const {
                data: { data: orgRes, meta },
            } = await getOrganisation(apiBasePath, apiKey, organisationId);

            const newState = {
                ...challengeState,
            };
            setOrganisation(orgRes);
        };
        fetchData()
            .then(() => {
                setLoading(false);
                setIsLoading(false);
            })
            .catch((e) => {
                console.error(e);
                setLoading(false);
                setIsLoading(false);
                setError(true);
            });
    };

    const handleDataChange = (type: any, formData: any, valid: boolean) => {
        const newState = {
            ...challengeState,
            [type]: formData,
        };
        const steps = challengeState.steps;
        steps[currentStep].valid = valid;
        setChallengeState({ ...newState, steps: steps });
    };

    const prevStep = () => {
        setCurrentStep(currentStep > 0 ? currentStep - 1 : 0);
        scrollToId("formTop", containerId);
    };

    const validatorRef = useRef();

    const nextStep = async () => {
        const validatorValid =
            validatorRef?.current?.validator?.allValid() ?? true;

        //If validator ref is invalid OR no additional questions
        if (
            !validatorValid ||
            !challengeState.challengeFormData.additionalQuestionsValid
        ) {
            //Show all messages - when answers invalid
            validatorRef?.current?.validator?.showMessages();
            return;
        }

        if (currentStep < challengeState.steps.length - 1) {
            setCurrentStep(currentStep + 1);
            scrollToId("formTop", containerId);
        } else {
            // dispatch events
            setLoading(true);

            const userData: McUserFormData = challengeState.userFormData;
            const challengeData: McChallengeFormData =
                challengeState.challengeFormData;
            const companyData: McCompanyFormData =
                challengeState.companyFormData;

            const payload = {
                answers: [],
                user_firstname: userData.firstname,
                user_lastname: userData.lastname,
                user_email: userData.email,
                user_password: userData.password,
                user_organisation_id: organisationId,
                user_linkedin_url: userData.linkedin_url,
                user_phone_number: userData.phone_number,

                challenge_title: challengeData.title,
                challenge_summary: challengeData.summary,
                challenge_description: challengeData.description,
                challenge_challenge_space_id: null,
                challenge_team_count_preference:
                    challengeData.team_count_preference,
                challenge_open_challenge_type:
                    challengeData.open_challenge_type,

                company_name: companyData.name,
                company_description: companyData.description,
                company_website_url: companyData.website_url,
                company_logo_url: companyData.logo_url,
                company_employee_count: companyData.employee_count,
                company_founded_at: mysqlDateOrEmpty(companyData.founded_at),
                company_iban: companyData.iban,
                company_street_name: companyData.street_name,
                company_house_number: companyData.house_number,
                company_house_number_extension:
                    companyData.house_number_extension,
                company_postal_code: companyData.postal_code,
                company_city_name: companyData.city_name,
            };

            createCompanyChallenge(apiBasePath, apiKey, payload)
                .then(() => {
                    setSubmitted(true);
                    setLoading(false);
                    scrollToId("submitSection", containerId);
                })
                .catch((request) => {
                    setLoading(false);
                    setChallengeState({
                        ...challengeState,
                        errors: request.response.data.errors,
                    });
                    scrollToId("submitSection", containerId);
                });
        }
    };

    const onScroll = () => {
        const submitSectionFromTop =
            submitSectionRef?.current.getBoundingClientRect();
        if (submitSectionFromTop.top <= 300) {
            setShowScrollButton(false);
        } else {
            if (!showScrollButton) {
                setShowScrollButton(true);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll, false);
        loadOrganisation();
        return () => {
            window.removeEventListener("scroll", onScroll, false);
        };
    }, []);

    switch (currentStep) {
        case 0:
            stepForm = (
                <ChallengeSubmitForm
                    containerId={containerId}
                    onDataChange={handleDataChange}
                    data={challengeState.challengeFormData}
                    apiBasePath={apiBasePath}
                    showOpenChallenge={
                        organisation?.attributes?.allow_global_open_challenge
                            ? true
                            : false
                    }
                    no_extra_questions={true}
                    apiKey={apiKey}
                    ref={validatorRef}
                />
            );
            break;
        case 1:
            stepForm = (
                <CompanySubmitForm
                    widgetObj={widgetObj}
                    onDataChange={handleDataChange}
                    data={challengeState.companyFormData}
                    ref={validatorRef}
                />
            );
            break;
        case 2:
            stepForm = (
                <UserSubmitForm
                    onDataChange={handleDataChange}
                    data={challengeState.userFormData}
                    ref={validatorRef}
                />
            );
            break;
        default:
            break;
        // do nothing
    }

    return !isLoading ? (
        !error ? (
            <div>
                <div className={styles.contentContainer}>
                    <div className={styles.contentWrapper}>
                        <div className={styles.stepsContainer}>
                            <div
                                style={{ position: "relative" }}
                                ref={submitSectionRef}
                                id="submitSection"
                            >
                                {loading && <Loader />}

                                {!submitted && (
                                    <div
                                        className={styles.formWrapper}
                                        style={{
                                            opacity: loading ? 0.5 : 1,
                                        }}
                                    >
                                        <div className={styles.formTitle}>
                                            <h2 className={styles.formH2}>
                                                Submit Open Challenge
                                            </h2>
                                        </div>
                                        <p className={styles.formIntro}>
                                            Submitting a challenge is easy.
                                            Provide a short title, some context
                                            and company details. We will review
                                            your challenge and if needed contact
                                            you to discuss.
                                        </p>

                                        <div
                                            className={styles.stepList}
                                            id="formTop"
                                        >
                                            {challengeState.steps.map(
                                                (step, index) => {
                                                    return (
                                                        <span
                                                            className={`${
                                                                styles.stepItem as string
                                                            } ${
                                                                index ===
                                                                currentStep
                                                                    ? (styles.stepActive as string)
                                                                    : ""
                                                            }`}
                                                            key={index}
                                                            data-index={index}
                                                        >
                                                            <span
                                                                style={{
                                                                    minWidth:
                                                                        "2rem",
                                                                    minHeight:
                                                                        "2rem",
                                                                }}
                                                                className={
                                                                    index ===
                                                                    currentStep
                                                                        ? styles.activeNumber
                                                                        : styles.number
                                                                }
                                                            >
                                                                {index + 1}
                                                            </span>
                                                            <span
                                                                className={
                                                                    styles.stepTitle
                                                                }
                                                            >
                                                                {step.title}
                                                            </span>
                                                        </span>
                                                    );
                                                }
                                            )}
                                        </div>

                                        <div className={styles.formTop}>
                                            {stepForm}
                                            {/* Print out only e-mail errror */}
                                            {challengeState.errors[
                                                "user_email"
                                            ] && (
                                                <div
                                                    style={{
                                                        marginBottom: "1rem",
                                                    }}
                                                >
                                                    <ErrorEmailTaken
                                                        redirectUrl={`${BASE_PATH}/account/challenges`}
                                                    />
                                                </div>
                                            )}

                                            {!isEmpty(challengeState.errors) &&
                                                Object.keys(
                                                    challengeState.errors
                                                ).map((index) => {
                                                    if (index == "user_email") {
                                                        return "";
                                                    }
                                                    return (
                                                        <span
                                                            style={{
                                                                color: "#DC2626",
                                                            }}
                                                            key={index}
                                                        >
                                                            {
                                                                challengeState
                                                                    .errors[
                                                                    index
                                                                ]
                                                            }
                                                        </span>
                                                    );
                                                })}

                                            <div className={styles.buttons}>
                                                {currentStep > 0 && (
                                                    <Button
                                                        wrapper="button"
                                                        variant="secondary"
                                                        onClick={prevStep}
                                                        className={
                                                            styles.prevBtn
                                                        }
                                                    >
                                                        &lsaquo; Previous step
                                                    </Button>
                                                )}
                                                <Button
                                                    wrapper="button"
                                                    variant="primary"
                                                    onClick={nextStep}
                                                    className={styles.nextBtn}
                                                >
                                                    {currentStep === 2
                                                        ? "Submit"
                                                        : "Next step"}{" "}
                                                    &rsaquo;
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {submitted && (
                                    <div className={styles.submittedContainer}>
                                        <h2 className={styles.submittedTitle}>
                                            Check your mail!
                                        </h2>
                                        <p className={styles.submittedText}>
                                            Thanks for submitting! Please{" "}
                                            <strong>
                                                check your email (also your spam
                                                folder)
                                            </strong>{" "}
                                            with a link to confirm your account.
                                            You will receive a notification when
                                            your challenge is approved.
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <p>Something went wrong, please contact MasterChallenge support</p>
        )
    ) : (
        <p>Loading</p>
    );
};

export default OpenChallenge;
