import { h, FunctionalComponent } from "preact";
import { useEffect, useState } from "preact/hooks";
import styles from "./style.scss";

export interface ICheckboxProps {
    className: string;
    onChange: void;
    checked?: boolean;
    label?:string
    labelClassName?:string
    [key: string]: any;
}

const Checkbox: FunctionalComponent<ICheckboxProps> = (
    props: ICheckboxProps
) => {
    
    const { className, onChange, label="", labelClassName="", ...rest } = props;
    const [checked, setChecked] = useState(props.checked ?? false);    

    useEffect(() => {
        setChecked(props.checked ?? false);
    }, [props.checked]);

    const changeState = (event) => {
        setChecked(!checked);
        if (onChange) {
            props.onChange(event);
        }
    };

    const defaultIcon = (
        <span className={styles.checkmark} />
    );
    return (
        <div className={styles.checkbox_holder}>
            <label
                className={`${styles.checkbox} ${className}`}
                htmlFor={rest?.id}
            >
                <input
                    className={styles.hidden}
                    type="checkbox"
                    checked={checked}
                    {...rest}
                    // do onchange afterwards
                    onChange={changeState}
                />
                {checked ? defaultIcon : ""}
            </label>
            <label
                className={labelClassName}
                htmlFor={rest?.id}
            >{label}</label>
        </div>
    );
};

export default Checkbox;
