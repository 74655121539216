import { h, render } from "preact";
import App from "./components/App";
import McEmbedWidgetsSDKOptions from "./models/McEmbedWidgetsSDKOptions";

export interface McRenderProps {
    options: McEmbedWidgetsSDKOptions;
    el: Element | HTMLElement;
    merge?: Element;
}

export interface McInitProps {
    options: McEmbedWidgetsSDKOptions;
    element: any;
    setOptions(changedOptions: any): McEmbedWidgetsSDKOptions;
    tearDown(): any;
}

/**
 * Renders the MV AddressSelector component
 * @param  {Object} options: McEmbedWidgetsSDKOptions configuration options
 * @param  {DOMElement} el:      target element where te component needs to be rendered
 * @param  {DOMElement} merge:   original element from first render (needed by preact for a rerender)
 * @return {DOMElement}          returns element from render
 */
const mcRender: any = ({ options, el, merge }: McRenderProps) =>
    render(<App options={options} />, el, merge);

const sdkConfigDefaults: McEmbedWidgetsSDKOptions = {
    organisationId: "7eca9478-6f51-462a-ab52-4d76f045e62c",
    apiKey: "17fc5fb2-70c4-4c22-af80-14830df514ce",
    containerId: "mc-embed-widget",
    apiBasePath: "http://localhost:8080",
    widgetOptions: {
        type: "spaces_overview",
        columnCount: 3,
        styles: {
            primaryColor: "#fcd34d",
            secondaryColor: "#5b21b6",
            buttonBorderRadius: "999px",
        },
    },
};

/**
 * Retrieves the style sheets from the shadow root of the style container element.
 *
 * @return {CSSStyleSheet[]} An array of CSS style sheets.
 */
const getStyleSheets = () => {
    const styleContainer = document.querySelector(
        "#mc-embed-style-container"
    ).shadowRoot;
    const styles = styleContainer?.querySelectorAll("style");
    const sheets = Array.from(styles).map((el) => {
        const sheet = new CSSStyleSheet();
        sheet.replaceSync(el.textContent);
        return sheet;
    });
    return sheets;
};

export const init = (opts: McEmbedWidgetsSDKOptions): McInitProps => {
    const options = {
        ...sdkConfigDefaults,
        ...opts,
        widgetOptions: {
            ...sdkConfigDefaults.widgetOptions,
            ...opts.widgetOptions,
            styles: {
                ...sdkConfigDefaults.widgetOptions.styles,
                ...opts.widgetOptions?.styles,
            },
        },
    };

    const sheets = getStyleSheets();

    const containerElOrg = document.querySelector(`#${opts.containerId}`);
    containerElOrg.attachShadow({ mode: "open" });

    // Apply the generic stylesheets to the dynamic shadow dom container
    const containerEl = containerElOrg.shadowRoot;
    containerEl.adoptedStyleSheets = [...sheets];

    const element = mcRender({ options, el: containerEl });

    return {
        options,
        element,
        /**
         * Merges the original options with the new ones
         * @param {Object} changedOptions the new options object
         */
        setOptions(changedOptions: McEmbedWidgetsSDKOptions) {
            this.options = { ...this.options, ...changedOptions };
            this.element = mcRender({
                options: this.options,
                el: containerEl,
                merge: this.element,
            });
            return this.options;
        },
        tearDown() {
            render(null, containerEl, this.element);
        },
    };
};
