import { h, FunctionalComponent } from "preact";
import { parse } from "preact-parser";
import queryString from "query-string";

import ChallengeSubmitForm from "../ChallengeSubmitForm";
import CompanySubmitForm from "../CompanySubmitForm";
import UserSubmitForm from "../UserSubmitForm";

import { useEffect, useRef, useState } from "preact/hooks";
import McEmbedWidgetsSDKOptions from "../../models/McEmbedWidgetsSDKOptions";
import {
    createCompanyChallenge,
    getChallengeSpace,
    getSubjects,
} from "../../services/api";
import McChallengeFormData from "../../models/McChallengeFormData";
import McCompanyFormData from "../../models/McCompanyFormData";
import McUserFormData from "../../models/McUserFormData";
import McChallengeFormStep from "../../models/McChallengeFormStep";
import UnsplashImage from "../UnsplashImage";

import styles from "./style.scss";
import { isEmpty, mysqlDateOrEmpty, scrollToId } from "../../services/helpers";
import ErrorEmailTaken from "../OpenChallenge/ErrorEmailTaken";
import Button from "../Button";
import Loader from "../Loader";

interface SubmitChallengeState {
    steps: McChallengeFormStep[];
    userFormData: McUserFormData;
    challengeFormData: McChallengeFormData;
    companyFormData: McCompanyFormData;
    errors: Record<string, never>;
    error: Record<string, never> | string;
    allowGlobalOpenChallenge: boolean;
}

const BASE_PATH = process.env.APP_BASE_PATH || "https://masterchallenge.me";

const SpaceDetails: FunctionalComponent<McEmbedWidgetsSDKOptions> = (
    props: McEmbedWidgetsSDKOptions
) => {
    const { organisationId, apiBasePath, apiKey, widgetOptions, containerId } =
        props;
    const widgetObj = {
        apiBasePath: apiBasePath,
        apiKey: apiKey,
        organisationId: organisationId,
        containerId,
    };

    const submitSectionRef = useRef(null);
    let stepForm;
    const [error, setError] = useState(false);
    const [space, setSpace] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [subjects, setSubjects] = useState([]);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [showScrollButton, setShowScrollButton] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [challengeState, setChallengeState] = useState<SubmitChallengeState>({
        steps: [
            {
                key: "challenge",
                title: "Your challenge",
                valid: false,
            },
            {
                key: "company",
                title: "Your company",
                valid: false,
            },
            {
                key: "user",
                title: "Register account",
                valid: false,
            },
        ],
        challengeFormData: {
            title: "",
            summary: "",
            team_count_preference: 1,
            description: "",
            is_open_challenge: false,
        },
        companyFormData: {
            city_name: "",
            description: "",
            employee_count: "",
            founded_at: "",
            house_number: "",
            house_number_extension: "",
            iban: "",
            logo_url: "",
            name: "",
            postal_code: "",
            street_name: "",
            website_url: "",
        },
        userFormData: {
            email: "",
            linkedin_url: "",
            phone_number: "",
            firstname: "",
            lastname: "",
            password: "",
        },
        errors: {},
        error: "",
        allowGlobalOpenChallenge: false,
    });

    const subjectParams = {
        page: {
            offset: 0,
            limit: 999,
        },
        sort: "title",
    };

    const handleDataChange = (type: any, formData: any, valid: boolean) => {
        const newState = {
            ...challengeState,
            [type]: formData,
        };
        const steps = challengeState.steps;
        steps[currentStep].valid = valid;
        setChallengeState({ ...newState, steps: steps });
    };

    const prevStep = () => {
        setCurrentStep(currentStep > 0 ? currentStep - 1 : 0);
        scrollToId("formTop", containerId);
    };

    const validatorRef = useRef();

    const nextStep = async () => {
        const validatorValid =
            validatorRef?.current?.validator?.allValid() ?? true;

        //If validator ref is invalid OR no additional questions
        if (
            !validatorValid ||
            !challengeState.challengeFormData.additionalQuestionsValid
        ) {
            //Show all messages - when answers invalid
            validatorRef?.current?.validator?.showMessages();
            return;
        }

        if (currentStep < challengeState.steps.length - 1) {
            setCurrentStep(currentStep + 1);
            scrollToId("formTop", containerId);
        } else {
            // dispatch events
            setLoading(true);

            const userData: McUserFormData = challengeState.userFormData;
            const challengeData: McChallengeFormData =
                challengeState.challengeFormData;
            const companyData: McCompanyFormData =
                challengeState.companyFormData;

            const payload = {
                answers: challengeData.answers,
                user_firstname: userData.firstname,
                user_lastname: userData.lastname,
                user_email: userData.email,
                user_password: userData.password,
                user_organisation_id: organisationId,
                user_linkedin_url: userData.linkedin_url,
                user_phone_number: userData.phone_number,

                challenge_title: challengeData.title,
                challenge_summary: challengeData.summary,
                challenge_description: challengeData.description,
                challenge_challenge_space_id: space.id,
                challenge_team_count_preference:
                    challengeData.team_count_preference,
                challenge_open_challenge_type:
                    challengeData.open_challenge_type,

                company_name: companyData.name,
                company_description: companyData.description,
                company_website_url: companyData.website_url,
                company_logo_url: companyData.logo_url,
                company_employee_count: companyData.employee_count,
                company_founded_at: mysqlDateOrEmpty(companyData.founded_at),
                company_iban: companyData.iban,
                company_street_name: companyData.street_name,
                company_house_number: companyData.house_number,
                company_house_number_extension:
                    companyData.house_number_extension,
                company_postal_code: companyData.postal_code,
                company_city_name: companyData.city_name,
            };

            createCompanyChallenge(apiBasePath, apiKey, payload)
                .then(() => {
                    setSubmitted(true);
                    setLoading(false);
                    scrollToId("submitSection", containerId);
                })
                .catch((err) => {
                    setLoading(false);
                    setChallengeState({
                        ...challengeState,
                        errors: err.response.data.errors,
                    });
                    scrollToId("submitSection", containerId);
                });
        }
    };

    const onScroll = () => {
        const submitSectionFromTop =
            submitSectionRef?.current.getBoundingClientRect();
        if (submitSectionFromTop.top <= 300) {
            setShowScrollButton(false);
        } else {
            if (!showScrollButton) {
                setShowScrollButton(true);
            }
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll, false);
        const fetchData = async () => {
            setIsLoading(true);
            const { apiBasePath, apiKey, widgetOptions } = props;
            let spaceId: string | null = null;

            if (widgetOptions.challengeSpaceId) {
                spaceId = widgetOptions.challengeSpaceId;
            } else {
                const parsed = queryString.parse(location.search);
                spaceId = parsed.spaceId;
            }

            if (!spaceId) {
                setNotFound(true);
                setIsLoading(false);
                return;
            }

            const {
                data: { data: spaceRes, included: included },
            } = await getChallengeSpace(apiBasePath, apiKey, spaceId, {
                include: "subject",
            });
            // Redirect if space is not active any more or not found
            if (!spaceRes || spaceRes.attributes.state !== "active") {
                setNotFound(true);
                setIsLoading(false);
                return;
            }

            setSpace(spaceRes);

            if (included) {
                const org = included.find(
                    (includedObject) => includedObject.type === "organisation"
                );

                const forceOpenChallengeType = spaceRes.attributes
                    .is_open_challenge_space
                    ? "organisation"
                    : "private";

                setChallengeState({
                    ...challengeState,
                    challengeFormData: {
                        ...challengeState.challengeFormData,
                        open_challenge_type: forceOpenChallengeType,
                    },
                    allowGlobalOpenChallenge:
                        org.attributes.allow_global_open_challenge &&
                        spaceRes.attributes.is_open_challenge_space,
                });
            }

            const {
                data: { data: subjectList },
            } = await getSubjects(apiBasePath, apiKey, subjectParams);
            const mappedSubjects = Object.keys(subjectList).map((key) => {
                return subjectList[key];
            });
            setSubjects(mappedSubjects);
        };
        fetchData()
            .then(() => {
                setIsLoading(false);
            })
            .catch((e) => {
                console.error(e);
                setIsLoading(false);
                setError(true);
            });
        return () => {
            window.removeEventListener("scroll", onScroll, false);
        };
    }, []);

    switch (currentStep) {
        case 0:
            stepForm = (
                <ChallengeSubmitForm
                    containerId={containerId}
                    onDataChange={handleDataChange}
                    data={challengeState.challengeFormData}
                    apiBasePath={apiBasePath}
                    challengeSpaceId={widgetOptions.challengeSpaceId}
                    showOpenChallenge={challengeState.allowGlobalOpenChallenge}
                    apiKey={apiKey}
                    ref={validatorRef}
                />
            );
            break;
        case 1:
            stepForm = (
                <CompanySubmitForm
                    widgetObj={widgetObj}
                    onDataChange={handleDataChange}
                    data={challengeState.companyFormData}
                    ref={validatorRef}
                />
            );
            break;
        case 2:
            stepForm = (
                <UserSubmitForm
                    onDataChange={handleDataChange}
                    data={challengeState.userFormData}
                    ref={validatorRef}
                />
            );
        default:
            break;
        // do nothing
    }

    const loginUrl = space?.attributes?.is_open_challenge_space
        ? `${BASE_PATH}/account/challenges`
        : `${BASE_PATH}/account/challenge-spaces/${space?.id}?tab=challenges`;

    const subject =
        subjects.length &&
        subjects.find((s) => s.id === space.relationships?.subject?.data?.id);

    return !notFound ? (
        !isLoading ? (
            space && !error ? (
                <div>
                    <div className={styles.container}>
                        <div className={styles.headerWrapper}>
                            <div className={styles.headerInner}>
                                {subject &&
                                    !space.attributes
                                        .is_open_challenge_space && (
                                        <span className={styles.subject}>
                                            {subject?.attributes?.title}
                                        </span>
                                    )}
                                <h1 className={styles.title}>
                                    {space.attributes.title}
                                </h1>
                                {!space.attributes.is_open_challenge_space && (
                                    <div className={styles.period}>
                                        {space.attributes.use_challenges && (
                                            <span
                                                className={styles.periodInner}
                                            >
                                                Period:{" "}
                                                {new Date(
                                                    space.attributes.challenge_start_date
                                                ).toLocaleDateString(
                                                    "nl-NL"
                                                )}{" "}
                                                -{" "}
                                                {new Date(
                                                    space.attributes.challenge_end_date
                                                ).toLocaleDateString("nl-NL")}
                                            </span>
                                        )}
                                    </div>
                                )}
                                {space.attributes.summary && (
                                    <p className={styles.summary}>
                                        {space.attributes.summary}
                                    </p>
                                )}
                                <div className={styles.content}>
                                    {!space.attributes.submission_expired &&
                                    space.attributes.use_challenges ? (
                                        <div className={styles.contentWrapper}>
                                            <Button
                                                variant="primary"
                                                wrapper="button"
                                                onClick={() =>
                                                    scrollToId(
                                                        "submitSection",
                                                        containerId
                                                    )
                                                }
                                            >
                                                Submit challenge
                                            </Button>
                                            {space.attributes
                                                .challenge_submit_deadline &&
                                                !space.attributes
                                                    .is_open_challenge_space && (
                                                    <span
                                                        className={
                                                            styles.deadline
                                                        }
                                                        style={{
                                                            maxWidth: "100%",
                                                        }}
                                                    >
                                                        Submission deadline:{" "}
                                                        <strong>
                                                            {new Date(
                                                                space.attributes.challenge_submit_deadline
                                                            ).toLocaleDateString(
                                                                "nl-NL"
                                                            )}
                                                        </strong>
                                                    </span>
                                                )}
                                        </div>
                                    ) : (
                                        space.attributes.use_challenges &&
                                        !space.attributes
                                            .is_open_challenge_space && (
                                            <div
                                                className={
                                                    styles.deadlinePassedContainer
                                                }
                                            >
                                                <span
                                                    className={
                                                        styles.deadlinePassed
                                                    }
                                                >
                                                    Deadline has passed
                                                </span>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.gradientOverlay}></div>

                        <div className={styles.imgContainer}>
                            <UnsplashImage
                                {...(space.attributes.unsplash ?? {})}
                                title={space.attributes.title}
                                width="1900"
                                height="1000"
                                ImgClassName={styles.img}
                                SpanClassName={styles.credit}
                                CreditlinkClassName={styles.creditLink}
                            />
                        </div>
                    </div>

                    <div className={styles.contentContainer}>
                        <div className={styles.contentWrapper}>
                            <div className={styles.topSection}>
                                <h3 className={styles.descriptionTitle}>
                                    Description
                                </h3>

                                {showScrollButton &&
                                    !submitted &&
                                    !space.attributes.submission_expired && (
                                        <Button
                                            variant="primary"
                                            wrapper="button"
                                            onClick={() =>
                                                scrollToId(
                                                    "submitSection",
                                                    containerId
                                                )
                                            }
                                            className={styles.floatingBtn}
                                            style={{
                                                bottom: "20px",
                                                left: "50%",
                                                transform: "translateX(-50%)",
                                            }}
                                        >
                                            Submit a challenge
                                        </Button>
                                    )}

                                <div className={styles.description}>
                                    {parse(space.attributes.description)}
                                </div>
                            </div>

                            <div className={styles.stepsContainer}>
                                <div
                                    style={{ position: "relative" }}
                                    ref={submitSectionRef}
                                    id="submitSection"
                                >
                                    {loading && <Loader />}

                                    {!submitted &&
                                        !space.attributes
                                            .submission_expired && (
                                            <div
                                                className={styles.formWrapper}
                                                style={{
                                                    opacity: loading ? 0.5 : 1,
                                                }}
                                            >
                                                <div
                                                    className={styles.formTitle}
                                                >
                                                    <h2
                                                        className={
                                                            styles.formH2
                                                        }
                                                    >
                                                        Submit Challenge
                                                    </h2>
                                                </div>
                                                <p className={styles.formIntro}>
                                                    Submitting a challenge is
                                                    easy. Provide a short title,
                                                    some context and company
                                                    details. We will review your
                                                    challenge and if needed
                                                    contact you to discuss.
                                                </p>

                                                <p
                                                    className={
                                                        styles.alreadyAccount
                                                    }
                                                >
                                                    Already have an account on
                                                    MasterChallenge?{" "}
                                                    <a
                                                        href={loginUrl}
                                                        rel="noreferrer"
                                                        target="_blank"
                                                        className={styles.login}
                                                    >
                                                        Login
                                                    </a>
                                                </p>
                                                <div
                                                    className={styles.stepList}
                                                    id="formTop"
                                                >
                                                    {challengeState.steps.map(
                                                        (step, index) => {
                                                            return (
                                                                <span
                                                                    className={`${
                                                                        styles.stepItem
                                                                    } ${
                                                                        index ===
                                                                        currentStep
                                                                            ? styles.stepActive
                                                                            : ""
                                                                    }`}
                                                                    key={index}
                                                                    data-index={
                                                                        index
                                                                    }
                                                                >
                                                                    <span
                                                                        style={{
                                                                            minWidth:
                                                                                "2rem",
                                                                            minHeight:
                                                                                "2rem",
                                                                        }}
                                                                        className={
                                                                            index ===
                                                                            currentStep
                                                                                ? styles.activeNumber
                                                                                : styles.number
                                                                        }
                                                                    >
                                                                        {index +
                                                                            1}
                                                                    </span>
                                                                    <span
                                                                        className={
                                                                            styles.stepTitle
                                                                        }
                                                                    >
                                                                        {
                                                                            step.title
                                                                        }
                                                                    </span>
                                                                </span>
                                                            );
                                                        }
                                                    )}
                                                </div>

                                                <div className={styles.formTop}>
                                                    {stepForm}

                                                    {challengeState.errors[
                                                        "user_email"
                                                    ] && (
                                                        <div
                                                            style={{
                                                                marginBottom:
                                                                    "1rem",
                                                            }}
                                                        >
                                                            <ErrorEmailTaken
                                                                redirectUrl={
                                                                    loginUrl
                                                                }
                                                            />
                                                        </div>
                                                    )}

                                                    {!isEmpty(
                                                        challengeState.errors
                                                    ) &&
                                                        Object.keys(
                                                            challengeState.errors
                                                        ).map((index) => {
                                                            if (
                                                                index ==
                                                                "user_email"
                                                            ) {
                                                                return "";
                                                            }
                                                            return (
                                                                <span
                                                                    style={{
                                                                        color: "#DC2626",
                                                                    }}
                                                                    key={index}
                                                                >
                                                                    {
                                                                        challengeState
                                                                            .errors[
                                                                            index
                                                                        ]
                                                                    }
                                                                </span>
                                                            );
                                                        })}

                                                    <div
                                                        className={
                                                            styles.buttons
                                                        }
                                                    >
                                                        {currentStep > 0 && (
                                                            <Button
                                                                className={
                                                                    styles.prevBtn
                                                                }
                                                                onClick={
                                                                    prevStep
                                                                }
                                                                variant="secondary"
                                                                wrapper="button"
                                                            >
                                                                &lsaquo;
                                                                Previous step
                                                            </Button>
                                                        )}

                                                        <Button
                                                            className={
                                                                styles.nextBtn
                                                            }
                                                            wrapper="button"
                                                            variant="primary"
                                                            onClick={nextStep}
                                                            type="button"
                                                        >
                                                            {currentStep === 2
                                                                ? "Submit"
                                                                : "Next step"}{" "}
                                                            &rsaquo;
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                    {submitted && (
                                        <div
                                            className={
                                                styles.submittedContainer
                                            }
                                        >
                                            <h2
                                                className={
                                                    styles.submittedTitle
                                                }
                                            >
                                                Check your mail!
                                            </h2>
                                            <p className={styles.submittedText}>
                                                Thanks for submitting! Please{" "}
                                                <strong>
                                                    check your email (also your
                                                    spam folder)
                                                </strong>{" "}
                                                with a link to confirm your
                                                account. You will receive a
                                                notification when your challenge
                                                is approved.
                                            </p>
                                        </div>
                                    )}

                                    {!submitted &&
                                        space.attributes.submission_expired && (
                                            <div
                                                className={
                                                    styles.expiredContainer
                                                }
                                            >
                                                <h2
                                                    className={
                                                        styles.expiredTitle
                                                    }
                                                >
                                                    Challenge submission expired
                                                </h2>
                                                <p
                                                    className={
                                                        styles.expiredMessage
                                                    }
                                                >
                                                    You can&apos;t submit new
                                                    challenges for this space.
                                                    Send us a message and we
                                                    will let you know when this
                                                    space starts again.
                                                </p>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <p>
                    Something went wrong, please contact MasterChallenge support
                </p>
            )
        ) : (
            <p>Loading</p>
        )
    ) : (
        <div>
            <p>This space is not found.</p>
        </div>
    );
};

export default SpaceDetails;
