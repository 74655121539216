// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mc-sdk-ui-QuestionFormElement-container{padding:10px 0}.mc-sdk-ui-QuestionFormElement-heading{margin-bottom:10px}.mc-sdk-ui-QuestionFormElement-comment{display:inline-block;margin-bottom:10px;font-size:14px}.mc-sdk-ui-QuestionFormElement-label{display:block;color:#374151;font-size:0.875rem;line-height:1.25rem;font-weight:700;margin-bottom:0.5rem}.mc-sdk-ui-QuestionFormElement-selectMenu{font-size:0.875rem}.mc-sdk-ui-QuestionFormElement-menuList{z-index:23}\n", "",{"version":3,"sources":["webpack://./components/QuestionFormElement/style.scss"],"names":[],"mappings":"AAAA,yCACI,cAAe,CAClB,uCAGG,kBAAmB,CACtB,uCAGG,oBAAqB,CACrB,kBAAmB,CACnB,cAAe,CAClB,qCAGG,aAAc,CACd,aAAc,CACd,kBAAmB,CACnB,mBAAoB,CACpB,eAAgB,CAChB,oBAAqB,CACxB,0CAGG,kBAAmB,CACtB,wCAGG,UAAW","sourcesContent":[".container {\n    padding: 10px 0;\n}\n\n.heading {\n    margin-bottom: 10px;\n}\n\n.comment {\n    display: inline-block;\n    margin-bottom: 10px;\n    font-size: 14px;\n}\n\n.label {\n    display: block;\n    color: #374151;\n    font-size: 0.875rem;\n    line-height: 1.25rem;\n    font-weight: 700;\n    margin-bottom: 0.5rem;\n}\n\n.selectMenu {\n    font-size: 0.875rem;\n}\n\n.menuList {\n    z-index: 23;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "mc-sdk-ui-QuestionFormElement-container",
	"heading": "mc-sdk-ui-QuestionFormElement-heading",
	"comment": "mc-sdk-ui-QuestionFormElement-comment",
	"label": "mc-sdk-ui-QuestionFormElement-label",
	"selectMenu": "mc-sdk-ui-QuestionFormElement-selectMenu",
	"menuList": "mc-sdk-ui-QuestionFormElement-menuList"
};
export default ___CSS_LOADER_EXPORT___;
