import { h, FunctionalComponent } from "preact";
import { useEffect, useReducer, useRef, useState } from "preact/hooks";
import SimpleReactValidator from "simple-react-validator";
import McUserFormData from "../../models/McUserFormData";

import styles from "./style.scss";
import RequiredSymbol from "../RequiredSymbol";
import { forwardRef, useImperativeHandle } from "react";

interface UserSubmitFormProps {
    onDataChange(type: string, params: any, valid: boolean): void;
    data: McUserFormData;
}

interface UserSubmitFormState extends McUserFormData {
    consent: string;
}

const BASE_PATH = process.env.APP_BASE_PATH || "https://masterchallenge.me";

const UserSubmitForm: FunctionalComponent<UserSubmitFormProps> = (
    { data, onDataChange }: UserSubmitFormProps,
    ref
) => {
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const [formState, setFormState] = useState<UserSubmitFormState>({
        firstname: "",
        lastname: "",
        linkedin_url: "",
        phone_number: "",
        email: "",
        password: "",
        consent: "",
    });
    const validatorConfig = {
        autoForceUpdate: { forceUpdate: forceUpdate },
        locale: "en",
        element: (message: string) => (
            <p className={styles.errorMessage}>{message}</p>
        ),
    };
    const validator = useRef(new SimpleReactValidator(validatorConfig));

    useImperativeHandle(ref, () => ({
        validator: validator.current,
    }));

    const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const value = target.checked ? target.checked : target.value;
        const name = target.name;

        const newState = {
            ...formState,
            [name]: value,
        };
        setFormState(newState);
    };

    useEffect(() => {
        onDataChange("userFormData", formState, validator.current.allValid());
    }, [formState]);

    useEffect(() => {
        setFormState({ ...formState, ...data });

        //Show error message for acceptign privacy policy
        validator.current.showMessageFor("privacy policy");
    }, []);

    return (
        <form className={styles.form} noValidate>
            <div style={{ marginBottom: "1rem" }}>
                <label htmlFor="firstname" className={styles.label}>
                    <RequiredSymbol /> Your first name
                </label>
                <input
                    className={styles.input}
                    name="firstname"
                    value={formState.firstname}
                    onChange={handleInputChange}
                    onBlur={() =>
                        validator.current.showMessageFor("first name")
                    }
                    id="firstname"
                    type="text"
                    placeholder="John"
                />
                {validator.current.message(
                    "first name",
                    formState.firstname,
                    "required|min:2"
                )}
            </div>
            <div style={{ marginBottom: "1rem" }}>
                <label htmlFor="lastname" className={styles.label}>
                    <RequiredSymbol /> Your last name
                </label>
                <input
                    className={styles.input}
                    name="lastname"
                    value={formState.lastname}
                    onChange={handleInputChange}
                    onBlur={() => validator.current.showMessageFor("last name")}
                    id="lastname"
                    type="text"
                    placeholder="Doe"
                />
                {validator.current.message(
                    "last name",
                    formState.lastname,
                    "required|min:2"
                )}
            </div>
            <div style={{ marginBottom: "1rem" }}>
                <label htmlFor="email" className={styles.label}>
                    <RequiredSymbol /> Your email
                </label>
                <input
                    className={styles.input}
                    name="email"
                    value={formState.email}
                    onChange={handleInputChange}
                    onBlur={() =>
                        validator.current.showMessageFor("email address")
                    }
                    id="email"
                    type="email"
                    placeholder="johndoe@example.com"
                />
                {validator.current.message(
                    "email address",
                    formState.email,
                    "required|email"
                )}
            </div>
            <div style={{ marginBottom: "1rem" }}>
                <label htmlFor="password" className={styles.label}>
                    <RequiredSymbol /> Your password
                </label>
                <small className={styles.intro}>
                    Must be at least 6 characters
                </small>
                <input
                    className={styles.input}
                    name="password"
                    value={formState.password}
                    onChange={handleInputChange}
                    onBlur={() => validator.current.showMessageFor("password")}
                    id="password"
                    type="password"
                    placeholder="Your password"
                />
                {validator.current.message(
                    "password",
                    formState.password,
                    "required|min:6"
                )}
            </div>
            <div style={{ marginBottom: "1rem" }}>
                <label htmlFor="password" className={styles.label}>
                    <RequiredSymbol /> Your phone number
                </label>
                <input
                    className={styles.input}
                    name="phone_number"
                    value={formState.phone_number}
                    onChange={handleInputChange}
                    onBlur={() =>
                        validator.current.showMessageFor("phone number")
                    }
                    id="phone_number"
                    type="text"
                    placeholder="Your phone number"
                />
                {validator.current.message(
                    "phone number",
                    formState.phone_number,
                    "required|min:2"
                )}
            </div>
            <div style={{ marginBottom: "1rem" }}>
                <label htmlFor="lastname" className={styles.label}>
                    Linkedin
                </label>
                <input
                    className={styles.input}
                    name="linkedin_url"
                    value={formState.linkedin_url}
                    onChange={handleInputChange}
                    onBlur={() => validator.current.showMessageFor("linkedin")}
                    id="linkedin_url"
                    type="text"
                    placeholder="https://linkedin.com/..."
                />
                {validator.current.message(
                    "linkedin",
                    formState.linkedin_url,
                    "url"
                )}
            </div>
            <div style={{ marginBottom: "1rem" }}>
                <label
                    className={styles.label}
                    style={{ width: "66.666667%" }}
                    htmlFor="consent"
                >
                    <input
                        style={{ marginRight: "0.5rem", lineHeight: 1.25 }}
                        type="checkbox"
                        value={formState.consent}
                        onBlur={() =>
                            validator.current.showMessageFor("privacy policy")
                        }
                        onChange={handleInputChange}
                        name="consent"
                        id="consent"
                    />
                    <span
                        style={{ fontSize: "0.875rem", lineHeight: "1.25rem" }}
                    >
                        <span style={{ fontWeight: "normal" }}>
                            I agree with the
                        </span>{" "}
                        <a
                            className={styles.link}
                            rel="noreferrer"
                            href={`${BASE_PATH}/static/files/privacy_policy.pdf`}
                            target="_blank"
                        >
                            privacy policy
                        </a>{" "}
                        and the{" "}
                        <a
                            className={styles.link}
                            rel="noreferrer"
                            href={`${BASE_PATH}/static/files/terms_and_conditions.pdf`}
                            target="_blank"
                        >
                            terms &amp; conditions
                        </a>
                    </span>
                </label>
                {validator.current.message(
                    "privacy policy",
                    formState.consent,
                    "accepted|required"
                )}
            </div>
        </form>
    );
};

export default forwardRef(UserSubmitForm);
