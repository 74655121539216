// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mc-sdk-ui-SpacesOverview-outerWrapper{width:100%}.mc-sdk-ui-SpacesOverview-container{margin:0 auto;display:flex;flex-wrap:wrap;width:100%}.mc-sdk-ui-SpacesOverview-containerWrapper{margin:0 -1rem}.mc-sdk-ui-SpacesOverview-loadMore{margin:0 auto;display:flex;align-items:flex-start;justify-content:center}.mc-sdk-ui-SpacesOverview-noResults{font-weight:600}.mc-sdk-ui-SpacesOverview-cardWrapper{padding:0 1rem;width:100%;margin-bottom:2rem;box-sizing:border-box}@media screen and (min-width: 768px){.mc-sdk-ui-SpacesOverview-cardWrapper{width:50%}}@media screen and (min-width: 1024px){.mc-sdk-ui-SpacesOverview-cardWrapper{width:33.33%}}@media screen and (min-width: 1024px){.mc-sdk-ui-SpacesOverview-cardColumn2{width:50%}}\n", "",{"version":3,"sources":["webpack://./components/SpacesOverview/style.scss"],"names":[],"mappings":"AAAA,uCACI,UAAW,CACd,oCAGG,aAAc,CACd,YAAa,CACb,cAAe,CACf,UAAW,CACd,2CAGG,cAAe,CAClB,mCAGG,aAAc,CACd,YAAa,CACb,sBAAuB,CACvB,sBAAuB,CAC1B,oCAGG,eAAgB,CACnB,sCAGG,cAAe,CACf,UAAW,CACX,kBAAmB,CACnB,qBAAsB,CACtB,qCALJ,sCAMQ,SAAU,CAMjB,CAJG,sCARJ,sCASQ,YAAa,CAGpB,CAGG,sCADJ,sCAEQ,SAAU,CAEjB","sourcesContent":[".outerWrapper {\n    width: 100%;\n}\n\n.container {\n    margin: 0 auto;\n    display: flex;\n    flex-wrap: wrap;\n    width: 100%;\n}\n\n.containerWrapper {\n    margin: 0 -1rem;\n}\n\n.loadMore {\n    margin: 0 auto;\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n}\n\n.noResults {\n    font-weight: 600;\n}\n\n.cardWrapper {\n    padding: 0 1rem;\n    width: 100%;\n    margin-bottom: 2rem;\n    box-sizing: border-box;\n    @media screen and (min-width: 768px) {\n        width: 50%;\n    }\n    @media screen and (min-width: 1024px) {\n        width: 33.33%;\n    }\n    // px-4 w-full md:w-1/2 lg:w-1/3 mb-8\n}\n\n.cardColumn2 {\n    @media screen and (min-width: 1024px) {\n        width: 50%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outerWrapper": "mc-sdk-ui-SpacesOverview-outerWrapper",
	"container": "mc-sdk-ui-SpacesOverview-container",
	"containerWrapper": "mc-sdk-ui-SpacesOverview-containerWrapper",
	"loadMore": "mc-sdk-ui-SpacesOverview-loadMore",
	"noResults": "mc-sdk-ui-SpacesOverview-noResults",
	"cardWrapper": "mc-sdk-ui-SpacesOverview-cardWrapper",
	"cardColumn2": "mc-sdk-ui-SpacesOverview-cardColumn2"
};
export default ___CSS_LOADER_EXPORT___;
