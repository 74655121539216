import { h, FunctionalComponent, Fragment } from "preact";
import UnsplashImage from "../UnsplashImage";

import styles from "./style.scss";
import Button from "../Button";

interface ChallengeSpaceCardProps {
    space: any;
    subject: any;
    key?: any;
    detailPage?: string;
}

const ChallengeSpaceCard: FunctionalComponent<ChallengeSpaceCardProps> = (
    props: ChallengeSpaceCardProps
) => {
    const { subject, space, detailPage } = props;
    return (
        <div className={styles.wrapper}>
            <a href={`${detailPage as string}?spaceId=${space.id as string}`}>
                <div className={styles.inner}>
                    <UnsplashImage
                        {...(space.attributes.unsplash ?? {})}
                        title={space.attributes.title}
                        width="600"
                        height="400"
                        ImgClassName={styles.img}
                        SpanClassName={styles.credit}
                        CreditlinkClassName={styles.creditLink}
                    />

                    <div className={styles.overlay}></div>
                    {subject && (
                        <span className={styles.subject}>
                            {subject.attributes.title}
                        </span>
                    )}
                </div>
            </a>
            <a
                href={`${detailPage as string}?spaceId=${space.id as string}`}
                className={styles.titleLink}
            >
                <h2 className={styles.title}>{space.attributes.title}</h2>
            </a>

            <div className={styles.period}>
                {space.attributes.use_challenges && (
                    <span className={styles.periodLabel}>
                        Period:{" "}
                        {new Date(
                            space.attributes.challenge_start_date as string
                        ).toLocaleDateString("nl-NL")}{" "}
                        -{" "}
                        {new Date(
                            space.attributes.challenge_end_date as string
                        ).toLocaleDateString("nl-NL")}
                    </span>
                )}
            </div>

            {space.attributes.summary && (
                <p className={styles.summary}>{space.attributes.summary}</p>
            )}

            <div className={styles.buttons}>
                <Button
                    variant="secondary"
                    wrapper="a"
                    href={`${detailPage as string}?spaceId=${
                        space.id as string
                    }`}
                >
                    More information
                </Button>
                {!space.attributes.submission_expired &&
                space.attributes.use_challenges ? (
                    <Fragment>
                        <Button
                            variant="primary"
                            wrapper="a"
                            href={`${detailPage as string}?spaceId=${
                                space.id as string
                            }&action=submitChallenge`}
                        >
                            <span>Submit challenge</span>
                        </Button>
                        {space.attributes.challenge_submit_deadline && (
                            <span className={styles.deadline}>
                                Submission deadline:{" "}
                                <strong>
                                    {new Date(
                                        space.attributes
                                            .challenge_submit_deadline as string
                                    ).toLocaleDateString("nl-NL")}
                                </strong>
                            </span>
                        )}
                    </Fragment>
                ) : (
                    space.attributes.use_challenges && (
                        <span className={styles.deadlinePassed}>
                            Deadline has passed
                        </span>
                    )
                )}
            </div>
        </div>
    );
};

export default ChallengeSpaceCard;
