// import React, { Component }  from 'react';
import { h, FunctionalComponent } from "preact";
import Select from "react-select";
import styles from "./style.scss";
import { countWords } from "../../services/helpers";
import ReactSelectElement from "../ReactSelectElement";

interface QuestionFormElementProps {
    question: any;
    value: any;
    onChange: (value: any, question: any) => void;
    parentStyles: any;
    validator: any;
    containerId: string;
}

const QuestionFormElement: FunctionalComponent<QuestionFormElementProps> = ({
    question,
    value,
    onChange,
    parentStyles,
    validator,
    containerId,
}: QuestionFormElementProps) => {
    const type = question.attributes.type;
    const isMulti = type === "select.multi";
    const isText = type === "text";

    const wordCount: number =
        question.attributes.type === "text" ? countWords(value || "") : -1;

    const wordCountAboveLimit: boolean =
        question.attributes.type === "text" &&
        wordCount > question.attributes.options.max_words;

    const isRequired: boolean = question.attributes.options?.required
        ? true
        : false;
    const isHiddenFromStudents: boolean =
        question.attributes.options.exclude_roles?.includes("student");

    return (
        <div className={styles.container}>
            <h4 className={styles.label}>
                <span style={{ color: "red" }}>{isRequired && "*"}</span>{" "}
                <span>{question.attributes.title}</span>
                {isHiddenFromStudents && (
                    <span
                        style={{
                            color: "gray",
                            fontSize: 11,
                        }}
                    >
                        {" "}
                        - Not visible to students
                    </span>
                )}
            </h4>
            {isText ? (
                <div>
                    <span
                        className={styles.comment}
                        style={{
                            color: wordCountAboveLimit ? "red" : "black",
                        }}
                    >
                        Maximum words:{" "}
                        <b>{question.attributes.options.max_words}</b>. Words
                        left:{" "}
                        <b>
                            {question.attributes.options.max_words - wordCount}
                        </b>
                    </span>
                    <textarea
                        onChange={(
                            evt: React.ChangeEvent<HTMLInputElement>
                        ) => {
                            onChange(evt.target.value, question);
                        }}
                        style={{
                            padding: 10,
                            width: "100%",
                            minHeight: 150,
                            borderColor: "hsl(0, 0%, 80%)",
                        }}
                        className={parentStyles.input}
                        value={value}
                    ></textarea>
                </div>
            ) : (
                <ReactSelectElement
                    shadowDomContainerId={containerId}
                    defaultValue={
                        Array.isArray(value)
                            ? value?.map((a: any) =>
                                  question.attributes.options.items.find(
                                      (b) => b.id === a
                                  )
                              )
                            : question.attributes.options.items.find(
                                  (b) => b.id === value
                              )
                    }
                    styles={{
                        menu: (base) => ({
                            ...base,
                            zIndex: "23",
                            fontSize: "0.875rem",
                        }),
                    }}
                    className={styles.selectMenu}
                    menuPosition={"fixed"}
                    onChange={(value) => onChange(value, question)}
                    isMulti={isMulti}
                    options={question.attributes.options.items}
                />
            )}

            {question.attributes.options.required && (
                <span className="block">
                    {validator.current.message("title", value, "required")}
                </span>
            )}
        </div>
    );
};

export default QuestionFormElement;
