// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mc-sdk-ui-Loader-loader{display:flex;position:absolute;top:0;right:0;bottom:0;left:0;z-index:20;justify-content:center;align-items:center;height:100%;background-color:rgba(255,255,255,0.5)}.mc-sdk-ui-Loader-loader svg{animation-name:mc-sdk-ui-Loader-spin;animation-duration:1000ms;animation-iteration-count:infinite;animation-timing-function:linear;width:2.5rem}@keyframes mc-sdk-ui-Loader-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}\n", "",{"version":3,"sources":["webpack://./components/Loader/styles.scss"],"names":[],"mappings":"AAAA,yBACI,YAAa,CACb,iBAAkB,CAClB,KAAM,CACN,OAAQ,CACR,QAAS,CACT,MAAO,CACP,UAAW,CACX,sBAAuB,CACvB,kBAAmB,CACnB,WAAY,CACZ,sCAA0C,CAX9C,6BAcQ,oCAAoB,CACpB,yBAA0B,CAC1B,kCAAmC,CACnC,gCAAiC,CACjC,YAAa,CAChB,iCAID,KACI,sBAAuB,CAE3B,GACI,wBAAyB,CAAA","sourcesContent":[".loader {\n    display: flex;\n    position: absolute;\n    top: 0;\n    right: 0;\n    bottom: 0;\n    left: 0;\n    z-index: 20;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n    background-color: rgba(255, 255, 255, 0.5);\n\n    svg {\n        animation-name: spin;\n        animation-duration: 1000ms;\n        animation-iteration-count: infinite;\n        animation-timing-function: linear;\n        width: 2.5rem;\n    }\n}\n\n@keyframes spin {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loader": "mc-sdk-ui-Loader-loader",
	"spin": "mc-sdk-ui-Loader-spin"
};
export default ___CSS_LOADER_EXPORT___;
