import { h, FunctionalComponent } from "preact";
import { useEffect, useReducer, useRef, useState } from "preact/hooks";
import React, { forwardRef, useImperativeHandle } from "react";
import DatePicker from "react-datepicker";
import SimpleReactValidator from "simple-react-validator";
import McCompanyFormData from "../../models/McCompanyFormData";
import { countWords } from "../../services/helpers";

import "react-datepicker/dist/react-datepicker.css";

import styles from "./style.scss";
import RequiredSymbol from "../RequiredSymbol";
import CompanyName from "../CompanyName";

interface CompanySubmitFormProps {
    onDataChange(type: string, params: any, valid: boolean): void;
    data: McCompanyFormData;
    widgetObj?: any;
}

const CompanySubmitForm: FunctionalComponent<CompanySubmitFormProps> = (
    { data, onDataChange, widgetObj }: CompanySubmitFormProps,
    ref
) => {
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const [formState, setFormState] = useState<McCompanyFormData>({
        name: "",
        website_url: "",
        logo_url: "",
        founded_at: "",
        employee_count: "",
        description: "",
        street_name: "",
        house_number: "",
        house_number_extension: "",
        iban: "",
        postal_code: "",
        city_name: "",
    });
    const validatorConfig: any = {
        autoForceUpdate: { forceUpdate: forceUpdate },
        locale: "en",
        validators: {
            maxwords: {
                // name the rule
                message: "A maximum of :values words is allowed",
                rule: (val, params) => {
                    return countWords(val) <= params;
                },
                messageReplace: (message, params) =>
                    message.replace(":values", params), // optional
            },
            prefixedUrl: {
                message:
                    "Link must start with https:// e.g. https://google.com",
                rule: (val: string) => val.startsWith("https://"),
            },
        },
        element: (message: string) => (
            <p className={styles.errorMessage}>{message}</p>
        ),
    };
    const validator = useRef(new SimpleReactValidator(validatorConfig));

    useImperativeHandle(ref, () => ({
        validator: validator.current,
    }));

    const handleInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const name = target.name;

        const newState = { ...formState, [name]: value };
        setFormState(newState);
    };

    const handleTextAreaChange = (
        event: React.FormEvent<HTMLTextAreaElement>
    ) => {
        const target = event.target as HTMLTextAreaElement;
        const value = target.value;
        const name = target.name;

        const newState = { ...formState, [name]: value };
        setFormState(newState);
    };

    const handleDatePickerChange = (date, name) => {
        const newState = { ...formState, [name]: date };
        setFormState(newState);
    };

    const handleCompanyNameChange = (companyData: any) => {
        setFormState({ ...formState, ...companyData });
        validator.current.showMessages();
    };

    useEffect(() => {
        onDataChange(
            "companyFormData",
            formState,
            validator.current.allValid()
        );
    }, [formState]);

    useEffect(() => {
        setFormState({ ...formState, ...data });
    }, []);

    return (
        <form className={styles.form} noValidate>
            <div style={{ marginBottom: "1rem" }}>
                <label htmlFor="name" className={styles.label}>
                    <RequiredSymbol /> Company name
                </label>
                <CompanyName
                    className={styles.input}
                    widgetObj={widgetObj}
                    value={formState.name ?? ""}
                    validator={validator.current}
                    changeData={handleCompanyNameChange}
                />
                {validator.current.message(
                    "name",
                    formState.name,
                    "required|min:2"
                )}
            </div>
            <div style={{ marginBottom: "1rem" }}>
                <label htmlFor="website" className={styles.label}>
                    <RequiredSymbol /> Website
                </label>
                <input
                    className={styles.input}
                    name="website_url"
                    value={formState.website_url}
                    onChange={handleInputChange}
                    onBlur={() => validator.current.showMessageFor("website")}
                    id="website_url"
                    type="url"
                    placeholder="https://..."
                />
                {validator.current.message(
                    "website",
                    formState.website_url,
                    "required|prefixedUrl|url"
                )}
            </div>
            <div style={{ marginBottom: "1rem" }}>
                <label htmlFor="website" className={styles.label}>
                    Logo url
                </label>
                <input
                    className={styles.input}
                    name="logo_url"
                    value={formState.logo_url}
                    onChange={handleInputChange}
                    onBlur={() => validator.current.showMessageFor("logo")}
                    id="logo_url"
                    type="url"
                    placeholder="https://..."
                />
                {validator.current.message(
                    "logo",
                    formState.logo_url,
                    "prefixedUrl|url"
                )}
            </div>
            <div style={{ marginBottom: "1rem" }}>
                <label className={styles.label}>Founding date</label>
                <DatePicker
                    selected={formState.founded_at}
                    showYearDropdown={true}
                    scrollableYearDropdown={true}
                    yearDropdownItemNumber={150}
                    maxDate={new Date()}
                    onFocus={(e) => e.target.blur()} // bit of a dirty hack to prevent editing the form field
                    onChange={(date: Date) =>
                        handleDatePickerChange(date, "founded_at")
                    }
                    placeholderText="Enter your founding date"
                    dateFormat="dd-MM-yyyy"
                    className={styles.input}
                    timeFormat={false}
                />
            </div>
            <div style={{ marginBottom: "1rem" }}>
                <label htmlFor="employee_count" className={styles.label}>
                    Amount of employees
                </label>
                <input
                    className={styles.input}
                    name="employee_count"
                    min="1"
                    value={formState.employee_count}
                    id="employee_count"
                    onChange={handleInputChange}
                    onBlur={() =>
                        validator.current.showMessageFor("employee count")
                    }
                    type="number"
                    placeholder="X"
                />
                {validator.current.message(
                    "employee count",
                    formState.employee_count,
                    "min:1,num|integer"
                )}
            </div>
            <div style={{ marginBottom: "1.5rem" }}>
                <label htmlFor="description" className={styles.label}>
                    <RequiredSymbol /> Company description
                </label>
                <p className={styles.intro}>
                    Tell us something about your company (max 150 words)
                </p>
                <textarea
                    className={styles.input}
                    name="description"
                    value={formState.description}
                    style={{ minHeight: "162px" }}
                    onChange={handleTextAreaChange}
                    onBlur={() =>
                        validator.current.showMessageFor("description")
                    }
                    id="description"
                    placeholder="Description"
                ></textarea>
                {validator.current.message(
                    "description",
                    formState.description,
                    "required|maxwords:150|min:3"
                )}
            </div>
            <div>
                <div className={styles.address}>
                    <div className={styles.addressInner}>
                        <label htmlFor="street_name" className={styles.label}>
                            <RequiredSymbol /> Street
                        </label>
                        <input
                            className={styles.input}
                            name="street_name"
                            type="text"
                            value={formState.street_name}
                            onChange={handleInputChange}
                            onBlur={() =>
                                validator.current.showMessageFor("street name")
                            }
                            id="street_name"
                            placeholder="Street name"
                        />
                        {validator.current.message(
                            "street name",
                            formState.street_name,
                            "required"
                        )}
                    </div>
                    <div
                        className={styles.house_number}
                        style={{ maxWidth: "120px" }}
                    >
                        <label htmlFor="house_number" className={styles.label}>
                            <RequiredSymbol /> House number
                        </label>
                        <input
                            className={styles.input}
                            style={{ maxWidth: "100%" }}
                            name="house_number"
                            type="number"
                            value={formState.house_number}
                            onChange={handleInputChange}
                            onBlur={() =>
                                validator.current.showMessageFor("house number")
                            }
                            id="house_number"
                            placeholder="House number"
                        />
                        {validator.current.message(
                            "house number",
                            formState.house_number,
                            "required|min:0,num|integer"
                        )}
                    </div>
                    <div className={styles.extension}>
                        <label
                            htmlFor="house_number_extension"
                            className={styles.label}
                        >
                            Extension
                        </label>
                        <input
                            name="house_number_extension"
                            className={styles.input}
                            type="text"
                            value={formState.house_number_extension}
                            onChange={handleInputChange}
                            id="house_number_extension"
                            placeholder="Extension"
                        />
                    </div>
                </div>
            </div>
            <div style={{ marginBottom: "1.5rem" }}>
                <div className={styles.zipCodeOuter}>
                    <div
                        className={styles.zipCodeInner}
                        style={{ maxWidth: "120px" }}
                    >
                        <label htmlFor="postal_code" className={styles.label}>
                            <RequiredSymbol /> Postal code
                        </label>
                        <input
                            className={styles.input}
                            name="postal_code"
                            type="text"
                            value={formState.postal_code}
                            onChange={handleInputChange}
                            onBlur={() =>
                                validator.current.showMessageFor("postal code")
                            }
                            id="postal_code"
                            placeholder="1234 XX"
                        />
                        {validator.current.message(
                            "postal code",
                            formState.postal_code,
                            "required|min:4"
                        )}
                    </div>
                    <div className={styles.zipCodeInner}>
                        <label htmlFor="city_name" className={styles.label}>
                            <RequiredSymbol /> City
                        </label>
                        <input
                            className={styles.input}
                            name="city_name"
                            type="text"
                            value={formState.city_name}
                            onChange={handleInputChange}
                            onBlur={() =>
                                validator.current.showMessageFor("city")
                            }
                            id="city_name"
                            placeholder="City"
                        />
                        {validator.current.message(
                            "city",
                            formState.city_name,
                            "required"
                        )}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default forwardRef(CompanySubmitForm);
