import { h, Fragment } from "preact";
import { kvkDetail, kvkSearch } from "../../services/api";

import kvkstyles from "./style.scss";
import ReactSelectElement from "../ReactSelectElement";

export interface ICompanyNameProps {
    value: any | string;
    validator: any;
    changeData: Function;
    filterOptions?: Function;
    valueInitFn?: Function;
    onChange?: Function;
    isNew?: Boolean;
    widgetObj?: any;
    className?: string;
}

const CompanyName = (props: ICompanyNameProps) => {
    const { value, validator, changeData, isNew = true, widgetObj } = props;
    const formatOptionLabel = (option: any, { context }: any) => {
        if (context == "value") {
            return option?.__isNew__
                ? option?.name
                : option?.attributes?.company_name;
        } else {
            if (option?.__isNew__) {
                return <div>{option?.name}</div>;
            } else {
                return (
                    <div>
                        <div className={kvkstyles.name}>
                            {option.attributes.company_name}
                        </div>
                        <div className={kvkstyles.line}>
                            <span className={kvkstyles.icon}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    style={{ width: "1rem" }}
                                >
                                    <path d="M64 112c-8.8 0-16 7.2-16 16v22.1l172.5 141.6c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16h384c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128c0-35.3 28.7-64 64-64h384c35.3 0 64 28.7 64 64v256c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
                                </svg>
                            </span>
                            <span>
                                Kvk: {option.attributes.kvknr} (
                                {option.attributes.company_type})
                            </span>
                        </div>
                        <div className={kvkstyles.line}>
                            {option.attributes?.street_name && (
                                <Fragment>
                                    <span className={kvkstyles.icon}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 384 512"
                                            style={{ height: "1.3rem" }}
                                        >
                                            <path d="M64 32c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h64v-64c0-35.3 28.7-64 64-64s64 28.7 64 64v64h64c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H64zm160 384c0-17.7-14.3-32-32-32s-32 14.3-32 32v64h64v-64zm-96 96H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0h256c35.3 0 64 28.7 64 64v384c0 35.3-28.7 64-64 64H128zM64 120c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H88c-13.3 0-24-10.7-24-24v-48zm32 8v32h32v-32H96zm152-32h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24h-48c-13.3 0-24-10.7-24-24v-48c0-13.3 10.7-24 24-24zm8 64h32v-32h-32v32zM64 248c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H88c-13.3 0-24-10.7-24-24v-48zm32 8v32h32v-32H96zm152-32h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24h-48c-13.3 0-24-10.7-24-24v-48c0-13.3 10.7-24 24-24zm8 64h32v-32h-32v32z" />
                                        </svg>
                                    </span>
                                    <span>
                                        {option.attributes?.street_name},{" "}
                                        {option.attributes?.city_name}
                                    </span>
                                </Fragment>
                            )}
                        </div>
                    </div>
                );
            }
        }
    };

    const defaultParams = {
        filter: {
            q: "",
        },
        page: {
            offset: 0,
            limit: 20,
        },
        sort: "-created_at",
    };

    const newOptionLabel = (value: string, label: any) => {
        return {
            id: value,
            name: label,
            __isNew__: true,
        };
    };

    const onChange = (res: any) => {
        if (res?.target) {
            changeData({ name: res.target.value });
            return;
        }
        // empty fields if new company is created
        let companyData: any = {
            city_name: "",
            website_url: "",
            description: "",
            employee_count: "",
            founded_at: "",
            house_number: "",
            house_number_extension: "",
            name: "",
            postal_code: "",
            street_name: "",
        };

        if (!res) {
            changeData(companyData);
        }

        if (res?.__isNew__) {
            companyData.name = res.id;
            changeData(companyData);
            validator.showMessageFor("name");
        } else if (res?.id) {
            kvkDetail(widgetObj.apiBasePath, widgetObj.apiKey, res.id)
                .then((res: any) => {
                    let detailObj = res?.data?.data?.attributes;
                    if (detailObj) {
                        for (let key in companyData) {
                            companyData[key] = detailObj[key] ?? "";
                        }
                        companyData["founded_at"] = new Date(
                            detailObj.founded_at
                        );
                    }
                    changeData(companyData);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const loadOptions = (inputValue: any, callback: Function) => {
        let params = { ...defaultParams };
        params.filter.q = inputValue;

        let resource = kvkSearch(
            widgetObj.apiBasePath,
            widgetObj.apiKey,
            params
        );
        resource.then((res) => {
            let options = res.data.data;
            callback(options);
        });
    };

    const debounce = (func: Function, timeout = 300) => {
        let timer: any;
        return (...args: any) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, timeout);
        };
    };

    const optionStyles = {
        control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            borderColor: "#e5e7eb",
            fontSize: "0.875rem",
        }),
        //ontrol: (styles) => ({ ...styles }),
        option: (styles: any, {}) => {
            return {
                ...styles,
                borderBottom: "1px solid #CCC",
                fontSize: "0.875rem",
            };
        },
    };

    if (!isNew) {
        return (
            <input
                className="w-full"
                name="name"
                value={value}
                onChange={onChange}
                onBlur={() => validator.showMessageFor("name")}
                id="name"
                type="text"
                placeholder="Company name"
            />
        );
    } else {
        return (
            <ReactSelectElement
                asyncCreatable
                shadowDomContainerId={widgetObj.containerId}
                className={"kvkselect"}
                formatCreateLabel={(val) => "Use: " + val}
                createOptionPosition={"first"}
                instanceId="name"
                name="name"
                value={value}
                placeholder={value != "" ? value : "Company name"}
                loadOptions={debounce(loadOptions, 300)}
                noOptionsMessage={() => "Type minimaal 1 character"}
                formatOptionLabel={formatOptionLabel}
                getNewOptionData={newOptionLabel}
                classNamePrefix="react-select"
                onChange={onChange}
                onBlur={() => validator.showMessageFor("name")}
                styles={optionStyles}
            />
        );
    }
};

export default CompanyName;
