import { h, FunctionalComponent, Fragment } from "preact";

export interface IUnsplashImageProps {
	// settings
	q: number
	fit: string
	crop: string
	ixlib: string
	width: number
	height: number

	// default image
	title: string
	link: string
	file_name: string
	ixid: string
	user_name: string
    
    //styles
    ImgClassName:string
    SpanClassName:string
    CreditlinkClassName:string
}

// see https://docs.imgix.com/apis/rendering
const defaultProps = {
	// default settings
	q: 80,
	fit: 'max',
	crop: 'entropy',
	ixlib: 'rb-4.0.3',

	// default image
	link: 'https://unsplash.com/photos/bJhT_8nbUA0?utm_source=masterchallenge&utm_medium=referral',
	file_name: 'photo-1502101872923-d48509bff386',
	ixid: 'M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA',
	user_name: 'Jukan Tateisi',

    //styles
    ImgClassName:'',
    SpanClassName:'',
    CreditlinkClassName:'',
}

export default function UnsplashImage(props: IUnsplashImageProps) {
	props = { ...defaultProps, ...props }
	return (
		<Fragment>
			<img
				className={"absolute w-full h-full object-cover "+props.ImgClassName}
				src={`https://images.unsplash.com/${props.file_name}?ixid=${props.ixid}&ixlib==${props.ixlib}&w=${props.width}&h=${props.height}&q=${props.q}&fit=${props.fit}&crop=${props.crop}`}
				alt={props.title}
			/>
			<span
				className={"text-white absolute z-20 right-2 bottom-2 "+props.SpanClassName}
				style={{ fontSize: '0.5rem' }}
			>
				Photo by{' '}
				<a
					onClick={(e) => {
						e.preventDefault()
						window.open(`${props.link}`)
					}}
					href={props.link}
					className={"font-semibold text-white "+props.CreditlinkClassName}
				>
					{props.user_name}
				</a>
			</span>
        </Fragment>
	)
}
