import { h, FunctionalComponent } from "preact";
import Select from "react-select";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import AsyncSelectCreatable from "react-select/async-creatable";

const ReactSelectElement: FunctionalComponent<any> = ({
    shadowDomContainerId,
    asyncCreatable = false,
    ...props
}: {
    shadowDomContainerId: string;
    asyncCreatable?: boolean;
    [key: string]: any;
}) => {
    const container = document.querySelector(`#${shadowDomContainerId}`)
        ?.shadowRoot as Node | undefined;
    const shadowrootCache = createCache({
        key: "mc-sdk-ui-rs",
        container: container,
        prepend: true,
    });
    return (
        <CacheProvider value={shadowrootCache}>
            {asyncCreatable ? (
                <AsyncSelectCreatable
                    {...props}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPosition="fixed"
                />
            ) : (
                <Select
                    {...props}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    menuPosition="fixed"
                />
            )}
        </CacheProvider>
    );
};

export default ReactSelectElement;
