// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mc-sdk-ui-App-global{-webkit-box-sizing:border-box;box-sizing:border-box}.mc-sdk-ui-App-global *,.mc-sdk-ui-App-global *::before,.mc-sdk-ui-App-global *::after{-webkit-box-sizing:border-box;box-sizing:border-box}\n", "",{"version":3,"sources":["webpack://./components/App/style.scss"],"names":[],"mappings":"AAAA,sBACI,6BAA8B,CAC9B,qBAAsB,CAF1B,uFAOQ,6BAA8B,CAC9B,qBAAsB","sourcesContent":[".global {\n    -webkit-box-sizing: border-box;\n    box-sizing: border-box;\n\n    *,\n    *::before,\n    *::after {\n        -webkit-box-sizing: border-box;\n        box-sizing: border-box;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"global": "mc-sdk-ui-App-global"
};
export default ___CSS_LOADER_EXPORT___;
