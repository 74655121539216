
      import API from "!../../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../../node_modules/style-loader/dist/runtime/styleDomAPI.js";
      
      import setAttributes from "!../../../node_modules/style-loader/dist/runtime/setAttributesWithoutAttributes.js";
      import insertStyleElement from "!../../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import styleTagTransformFn from "!../../../node_modules/style-loader/dist/runtime/styleTagTransform.js";
      import content, * as namedExport from "!!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[2].use[3]!./style.scss";
      
      

var options = {};

options.styleTagTransform = styleTagTransformFn;
options.setAttributes = setAttributes;
options.insert = function insertIntoTarget(element, options) {
      var styleContainer = document.querySelector("#mc-embed-style-container");
      if (!styleContainer) {
        styleContainer = document.createElement("div");
        styleContainer.setAttribute("id", "mc-embed-style-container");
        styleContainer.attachShadow({
          mode: "open"
        });
        document.body.append(styleContainer);
      }
      styleContainer.shadowRoot.appendChild(element);
    };
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[2].use[3]!./style.scss";
       export default content && content.locals ? content.locals : undefined;
