// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mc-sdk-ui-Checkbox-checkbox_holder{display:flex}.mc-sdk-ui-Checkbox-checkbox_holder label{padding-top:4px;padding-left:10px}.mc-sdk-ui-Checkbox-checkmark{margin:0px 0px 0px 6px;border-width:0px 4px 4px 0px;border-color:#6d28d9;border-style:solid;align-self:center;transform:scale(0.7) rotate(45deg);width:0.75rem;height:1.25rem;display:inline-block;top:0;left:0;position:absolute}.mc-sdk-ui-Checkbox-checkbox{position:relative;height:1.6rem;line-height:1.6rem;width:1.6rem;display:flex;border-width:1px;border-radius:0.25rem;border-color:#e5e7eb;border-style:solid;background:#FFF;cursor:pointer}.mc-sdk-ui-Checkbox-hidden{visibility:hidden}\n", "",{"version":3,"sources":["webpack://./components/Checkbox/style.scss"],"names":[],"mappings":"AAAA,oCACI,YAAY,CACf,0CAGG,eAAe,CACf,iBAAiB,CACpB,8BAGG,sBAAuB,CACvB,4BAA6B,CAC7B,oBAAmC,CACnC,kBAAmB,CACnB,iBAAkB,CAClB,kCAAkC,CAClC,aAAc,CACd,cAAe,CACf,oBAAqB,CACrB,KAAK,CACL,MAAM,CACN,iBAAkB,CACrB,6BAGG,iBAAkB,CAClB,aAAa,CACb,kBAAkB,CAClB,YAAY,CACZ,YAAa,CACb,gBAAiB,CACjB,qBAAsB,CACtB,oBAAgC,CAChC,kBAAmB,CACnB,eAAe,CACf,cAAc,CACjB,2BAGG,iBAAkB","sourcesContent":[".checkbox_holder{\n    display:flex;\n}\n\n.checkbox_holder label{\n    padding-top:4px;\n    padding-left:10px;\n}\n\n.checkmark{\n    margin: 0px 0px 0px 6px;\n    border-width: 0px 4px 4px 0px;\n    border-color: rgba(109, 40, 217, 1);\n    border-style: solid;\n    align-self: center;\n    transform: scale(.7) rotate(45deg);\n    width: 0.75rem;\n    height: 1.25rem;\n    display: inline-block;\n    top:0;\n    left:0;\n    position: absolute;\n}\n\n.checkbox{\n    position: relative;\n    height:1.6rem;\n    line-height:1.6rem;\n    width:1.6rem;\n    display: flex;;\n    border-width: 1px;    \n    border-radius: 0.25rem;\n    border-color: rgb(229, 231, 235);\n    border-style: solid;  \n    background:#FFF;\n    cursor:pointer;\n}\n\n.hidden{\n    visibility: hidden;;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox_holder": "mc-sdk-ui-Checkbox-checkbox_holder",
	"checkmark": "mc-sdk-ui-Checkbox-checkmark",
	"checkbox": "mc-sdk-ui-Checkbox-checkbox",
	"hidden": "mc-sdk-ui-Checkbox-hidden"
};
export default ___CSS_LOADER_EXPORT___;
